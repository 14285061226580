export default {
    LANGUAGE:{
        NAME:"Espanhol"
    },
    SISTEMA:{
        ENVIAR: "Enviar",
        EDITAR: "Editar",
        INCLUIR: "Incluir",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",        
        SOLICITANTE: "Solicitante",
        EXECUTANTE: "Executante",
        CONFIRMAR: "Confirmar",
        CONVENIO: "Seguro de salud",
        PACIENTE: "Paciente",
        UNIDADE: "Unidad",
        FUNCIONARIO: "Empleado",
        TIPOCONVENIO: "Tipo de seguro",
        SETOR: "Sector",
        EXAME: "Exame",
        FORNECEDOR: "Proveedor",
        CATEGORIAFINANCEIRA: "Categoria financeira",
        SOMENTELEITURA: "Solo lectura",
        NOVO: "Nuevo",        
        SELECIONEDATAHORA: "Selecione a data e hora",
        ATENCAO: "Atención!",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        PROCURAR: "Buscar",
        ERROSALVAR: "Error al guardar: ",
        SELECMODEL: "Seleccione el Ejemplo",
        JANEIRO: "Enero",
        FEVEREIRO: "Febrero",
        MARCO: "Marzo",
        ABRIL: "Abril",
        MAIO: "Mayo",
        JUNHO: "Junio",
        JULHO: "Julio",
        AGOSTO: "Agosto",
        SETEMBRO: "Septiembre",
        OUTUBRO: "Octubre",
        NOVEMBRO: "Noviembre",
        DEZEMBRO: "Diciembre",

    },
    GREETINGS: {
        BOMDIA: "¡Buenos días!",
        BOATARDE: "¡Buenas tardes!",
        BOANOITE: "¡Buenas noches!",
    },
    PORTAL:{
        SAUDACOES:"Hola,",
        INSTRUCOES: "A través de esta página puede seguir todas las pruebas solicitadas en este laboratorio.",
        PESQUISA: "Con el fin de mejorar nuestros servicios, le pedimos que responda a las siguientes encuestas",
        ANALISE: "Análisis clínicos",
        RADIOLOGIA: "Radiología",
        NUMEROACESSO: "Número de acceso",
        VIEWER: "Visor",
        DATAEMISSAO: "Fecha de emisión",
        RESULTADO: "Resultado",
    },
    GUIAS:{
        DATAEMISSAO: "Fecha de emisión",
        NUMEROGUIA: "Número de la guía",
        PACIENTENOME: "Nombre del paciente",
        FILTROS: "Filtros",
        GUIA: "Guía",
        RETORNO: "Regreso",
        RACAESPECIE: "Raza/Especies",
        DATASOLICITACAO: "Fecha de la solicitud",
        DATAENTREGA: "Fecha de entrega",
        DATAPREVISTA: "Fecha prevista",
        PRECO: "Precio",
        AUTORIZACAO: "Sin autorización del seguro de salud!",
        CONTATO: "Póngase en contacto con el laboratorio.",
        ABRIRRESULTADOS: "Resultados abiertos",
        EDITAR: "Editar la guía",
        ETIQUETA: "Imprimir la etiqueta",
        COMPROVANTE: "Imprimir bono",
        ENVIAEMAIL: "Enviar por correo electrónico",
        VERMAIS: "Haga clic para ver más información sobre cada examen",
        PRONTOS: "Exámenes listos",
        SEMRESULTADO: "No se han encontrado exámenes para los filtros seleccionados!",
        REFERENCIA: "Referencia",
        RESPONSAVEL: "Responsable",
    },
    PESQUISASATISFACAO:{
        TITULOPORTAL: "Portal",
        TITULOPESQUISA: "Encuesta de satisfacción",
        SALVAR: "Guardar",
        VOLTAR: "Volver al"
    },
    FILTROS:{
        FILTRO: "Filtros",
        PDF:"PDF",
        PERIODO: "Periodo",
        PROCURAR: "Búsqueda por nombre/Fecha de nacimiento/Código de guía/Referencia de la guía",
        INTEGRADORES: "Integradores",
        INTEGRADOR: "Integrador",
        LOCAL: "Sitio",
        TODOS: "Todos",
        PARCIAIS: "Parciales",
        PRONTOS: "Listo",
        ATRASADOS: "Atrasos",
        RECOLETA: "Recoger",
        FILTRAR: "Filtro por",
        DATASOLICITACAO: "Fecha de la solicitud",
        DATARETORNO: "Fecha de regresso",
        DATARECEBIMENTO: "Fecha de recepción de materiales",
        DATAASSINATURA: "Fecha firma",
        RESULTADOIMPRESSO: "Solo resultados no impresos",
        EXAMEPORTAL: "Sólo exámenes del portal",
        ORDEM: "Clasificar por",
        DATAEMISSAO: "Fecha de emisión",
        NUMEROGUIA: "Número de la guía",
        PACIENTENOME: "Nombre del paciente",
        MODIFICACAO: "Última modificación",
        SITUACAO: "Situación",
        SITUACAOINFO: "Las situaciones Parciales y Listo se basan en los exámenes mecanografiado en la guía",
        PEDIDOSLOTE: "Solicitudes sin lote",
        LOTESENVIADO: "Lotes enviados",
        LOTENAORECEBIDO: "Lotes enviados y no recibidos",
        LOTERECEBIDO: "Lotes recibidos",
        AMOSTRAIMPRESSA: "Muestras sin imprimir",
        BUSCAR: "Buscar en",
        CANCELAR: "Cancelar",
        SEMREPASSE: "Solo exámenes sin traslado",
        COMREPASSE: "Solo exámenes con traslado",
        CENTRORESULTADOS: "Centro de Resultados",
        CONTACORRENTE: "Cuenta corriente",
        SELECIONECENTROSRESULTADOS: "Selecione os centros de resultado",
        OCULTARVALORBRUTO: "Ocultar importe bruto",
        DATACRIACAO: "Creación",
        DATAVENCIMENTO: "Plazos vencidos",
        DATAPAGAMENTO: "Pago",
        DATACOMPETENCIA: "Competencia",
        BTNFLUXOCAIXA: "Flujo de caja diário",
        BTNFLUXO: "Flujo",
        BTNFECHAMENTOCAIXA: "Pagos en caja",
        BTNFECHAMENTO: "Cierre",
        BTNRELACAODEBITOS: "Lista de deudas ",
        BTNDEBITOS: "Deudas",
    },
    ORCAMENTO:{
        EXAMES: "Exámenes",
        EXAMESENCONTRADOS: "No se han encontrado examen",
        EXAME: "Examen",
        APELIDO: "Apodo",
        NOME: "Nombre",
        PRECO: "Precio",
        TOTALEXAME: "Exámenes totales",
        BLOQUEADO: "Bloqueo",
    },
    PACIENTESCONVENIO:{
        ID: "Id",
        BUSCARPORNOME: "Buscar enmbre",
        INCLUIR: "Incluir",
        NOME: "Nombre",
        SEXO: "Sexo",
        NASCIMENTO: "Nacimiento",
        INSCRICAO: "Inscripción",
        CPF: "Seguro social",
    },
    LOTES:{
        CODIGOBARRAS: "Código de barras",
        INCLUIR: "Incluir",
        INCLUIRPENDENCIA: "Incluir pendiente",
        CONTADOR: "Contable",
        AMOSTRA: "Muestra",
        MATERIAL: "Material",
        CONSERVANTE: "Conservador",
        EXAMES: "Exámenes",
        TEMPERATURA: "Temperatura",
        LATITUDE: "Latitud",
        LONGITUDE: "Longitud",
        AMOSTRASELECIONADA: "Muestra ya seleccionada",
        AMOSTRAJAINCLUIDA: "La muestra {msg} ya está incluida.",
    },
    CATALOGOEXAMES:{
        APELIDONOME: "Búsqueda por apellido/nombre",
        CODIGO: "Código",
        ID: "Id",
        NOME: "Nombre",
        APELIDO: "Apodo",
        VISUALIZAR: "Ver",
    },
    AMOSTRAS: {
        SELECIONARAMOSTRA: "Seleccionar/deseleccionar todas las muestras",
        IMPRIMIRAMOSTRA: "Muestras de impresión",
        FILTROS: "Filtros",
        AMOSTRASPERIODO: "Muestras en el periodo de "
    },
    AMOSTRA: {
        AMOSTRACANCELADA: "Muestra cancelada" ,
        AMOSTRAEXAMECANCELADA: "Muestra con todas los exámenes anuladas",
        AMOSTRAINCIDENCIA: "Muestra con incidencia",
        CODIGOBARRAS: "Código de barras",
        MATERIAL: "Material",
        CONSERVANTE: "Conservante",
        CODIGOETIQUETA: "Etiqueta de precolección",
        CODIGOINTEGRACAO: "Etiqueta de integración",
        LOTE: "Lote",
        CONGELADO: "Acondicionamiento: Congelado",
        REFRIGERADA: "Acondicionamiento: Refrigerado",
        AMBIENTE: "Acondicionamiento: Entorno",
        ETIQUETA: "Acondicionamiento: Etiqueta",
        EXAMEASSINADO: "Examen firmado",
        EXAMEDIGITADO: "Examen mecanografiado",
        EXAMECANCELADO: "Examen anulado",
        IDENTIFICACAO: "Identificación",
        PORTALCOLETADOR: "Coletador",
        PORTALCOLETADORDATA: "Data/Hora",        
    },
    RELACAOEXAME: {
        SOLICITADO: "Solicitado en",
        TOTAIS: "Totales",
        GUIAS: "Guías",
        PACIENTES: "Pacientes y",
        EXAMES: "Exámenes",
        EXAMESPERIODO: "Exámenes solicitados en el periodo de "
    },
    VISUALIZAREXAMES:{
        APELIDO: "Apodo",
        NOME: "Nombre",
        QTDEAMOSTRAS: "Cantidad de muestras",
        PRAZODIA: "Días de plazo",
        PRAZOHORA: "Horas límite",
        PRAZOMINUTO: "Plazo del minuto",
        TEMPOJEJUM:  "Tiempo mínimo de ayuno",
        VOLUMEMINIMO: "Volume mínimo",
        VOLUMEMINIMODESEJADO: "Volumen deseado",
        MEDICAMENTO: "Medicina",
        DUM:"Fecha de la última menstruación",
        PESO: "Peso",
        ALTURA: "Estatura",
        MASCARAS: "Ver las máscaras",
        VERSÃO: "Versión",
        SINONIMIA: "Sinonimia",
        RECIPIENTES: "Contenedores",
        OBTERAMOSTRA: "Obtención de una muestra",
        INFOLABORATORIO: "Información al laboratorio",
        INFOPACIENTE: "Información al paciente",
        APLICACAOOCLINICA: "Aplicación clínica / interpretación",
        CRITREJEICAO: "Criterios de rechazo",
        TABELA: "Tabla de precios",
        BLOQUEADO: "Bloqueo",
        NEGOCIADO: "Negociado",
        VOLTAR: "Volver",
        CODIGO: "Código",
        MATERIAL: "Material",
        CONSERVANTE: "Conservador",
        ESTABILIDADEAMBIENTE: "Estabilidad en el ambiente (h)",
        ESTABILIDADEREFRIGERADO: "Estabilidad en refrigeración (h)",
        ESTABILIDADECONGELADO: "Estabilidad congelada (h)",
    },
    RESULTADOS: {
        IMPRIMIRRESULTADO: "Imprimir los resultados",
        GUIA: "Guía",
        REFERENCIA: "Referencia",
        EMISSAO: "Emisión",
        PACIENTEID: "Paciente Id",
        PACIENTENOME: "Paciente nombre",
        LOCAL: "Sitio",
        LIBERACAO: "Liberación",
        LIBERACAOINFO: "Liberación es definida a partir de los exámenes firmados en la guía",
        NENHUM: "Ninguna",
        PARCIAL: "Parcial",
        TOTAL: "Total",
        RESULTADOSPERIODO: "Guías con resultados emitidos en el periodo de "
    },
    AVISOS: {
       PEDIDO: "Solicitud", 
       AMOSTRA: "Muestra",
       TIPO: "Tipo",
       EXAMES: "Exámenes",
       MOTIVO: "Cambio de máscara"
    },
    RECOLETAS: {
        PEDIDO: "Solicitud", 
        CODIGOBARRAS: "Código de barras",
        SOLICITADA: "Solicitado en",
        MOTIVO: "Razón",
        EXAMES: "Exámenes",
        ETIQUETA: "Imprimir la etiqueta",
    },
    PORTALESTOQUE: {
        EDITAR: "Editar",
        CODIGO: "Código",
        REFERENCIA: "Referencia",
        DATA: "Data",
        USUARIO: "Usuario",
        STATUS: "Estado",
        INCLUIR: "Incluir",
        PESQUISAR: "Pesquisar por Referencia ou Data",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ATENCAO: "Atención!",
        CAMPOSOBRIGATORIOS: "Rellene todos los campos!",
        NOME: "Nome",
        TITULO: "Cadastro",
        SALVAR: "Salvar",
        RECEBER: "Receber",
        CANCELAR: "Cancelar",
        SALVANDO: "Guardando...",
        SITUACAO: "Situação do pedido",
        SELECIONELOCAL: "Seleccione lo local",
        LOCAL: "Local",
        ESTOQUE: "Estoque",
        SELECIONEESTOQUE: "Seleccione lo estoque",
        ITENS: "Itens do pedido",
        ITENSOBRIGATORIOS: "Agregue al menos 1 artículo al pedido",
        QUANTIDADESOBRIGATORIAS: "Todos los artículos deben tener una cantidad válida",
        ACOMPANHAMENTO: "Seguimento",
        REPASSEMEDICO: "Revisión médica"
    },
    NAVBARCONVENIO: {
        GUIAS: "Guías",
        ORCAMENTO: "Presupuesto",
        INCLUIRGUIA: "Incluya la guía",
        ENVIARLOTE: "Enviar el lote",
        CATALOGO: "Catálogo",
        DEEXAMES: "de los exámenes",
        AMOSTRAS: "Muestras",
        EXAMES: "Exámenes",
        ESTOQUE: "Existencias",
        RESULTADOS: "Resultados",
        AVISOS: "Avisos",
        RECOLETA: "Recoger",
        REMARCACAO: "Reprogramación",
        VALORESCRIT: "Valores críticos",
        INTEGRACAO: "Integraciones",
        FATURAS: "Facturas",
        CADASTROS: "Cadastros"
    },
    VALORCRITICO: {
        PEDIDO: "Solicitud", 
        EXAMES: "Exámenes",
    },
    GUIA:{
        INDENTIFICACAO: "Identificación",
        REFERENCIA: "Referencia",
        LOCALORIGEM: "Lugar de origen",
        SELECIONELOCAL: "Seleccione su lugar de origen",
        EMITIDA: "Emitido en",
        HORASJEJUM: "Horas de ayuno",
        CALCULARHORASJEJUM: "Haga clic para calcular las horas de ayuno | Última comida hace 1 hora | Última comida hace {n} horas",
        INSCRICAO: "Inscripción",
        SEXO: "SEXO",
        NASCIMENTO: "Nacimiento",
        CBO: "CBO-S",
        EXAMES: "Exámenes",
        AMOSTRAS: "Muestras",
        INCLUIREXAME: "Incluir el examen",
        PROCURAR: "Buscar en",
        APELIDO: "Apodo",
        NOME: "Nombre",
        CODIGOEXTERNO: "Código externo",
        IDENTIFICACAO: "Identificación",
        NENHUMEXAME: "No hay examen disponible.",
        BLOQUEADO: "BLOQUEO",
        JEJUM: "Ayuno",
        CANCELARSELECIONADOS: "Cancelar los exámenes seleccionados",
        INCLUIR: "Incluir",
        IMPRIMIRCOMPROVANTE: "Imprimir bono",
        COMPROVANTE: "Recibo",
        OBSERVACOES: "Observaciones",
        HIPOTESE: "Hipótesis diagnóstica",
        CID10: "CID10",
        INDICACAO: "Indicación clínica",
        MEDICAMENTOS: "Medicamentos",
        INCLUIRMEDICAMENTO: "Incluya un medicamento",
        RESPOSTAS: "Respuestas",
        NENHUMARESPOSTA: "No hay respuesta",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MEDICOOBRIGATORIO: "Solicitante obligatorio",
        MENSAGEMMEDICO: "Especifique un solicitante para continuar.",
        LOCALOBRIGATORIO: "Lugar de origen obligatorio",
        MENSAGEMLOCAL: "Especifique un lugar de origen para continuar.",
        EXAMEOBRIGATORIO: "Exámenes obligatorios",
        MENSAGEMEXAME: "La guía debe tener al menos 1 examen.",
        MEDICAMENTOOBRIGATORIO: "Medicamento obligatorio",
        MENSAGEMMEDICAMENTO: "Estas pruebas necesitan que informes de los <b>medicamentos</b> en uso.",
        HIPOTESEDIAGNOSTICAOBRIGATORIA: "Hipótesis Diagnóstica obligatoria",
        MENSAGEMHIPOTESEDIAGNOSTICA: "Se deve informar la <b>hipótesis diagnóstica</b> para este examen.",
        PESOOBRIGATORIO: "Peso obligatorio",
        MENSAGEMPESO: "Confirmar la inclusión del peso del paciente ?",
        ALTURAOBRIGATORIA: "Estatura obligatoria",
        MENSAGEMALTURA: "Confirma la inclusión de la estatura del paciente  ?",
        CONFIRMA: "Confirmar",
        EXAMEINCLUIDO: "Examen ya incluido",
        MENSAGEMINCLUIDO: "Confirma la inclusión del examen: ",
        BLOQUEIOEXAME: "Bloqueo del examen duplicados activo",
        MESSAGEMBLOQUEIO: "Para introducir este examen más de una vez, solicite la eliminación del bloqueo de examen duplicado en el registro de pruebas",
        BLOQUEIOEXAMEGENERO: "Bloqueo del examen por género",
        BLOQUEIOEXAMEGENEROMENSAGEM: "Este examen no puede ser elegido para este paciente.",
        INSERIREXAME: "Inclusión del examen",
        MENSSAGEMINSERIREXAME: "El examen ya se ha encontrado",
        NESTAGUIA: "en esta ficha.",
        EXAMECOMPLEMENTARMENSAGEM: "El examen complementario ",
        NAOPODEINSERIR: " no se pudo introducir para este paciente.",
        BLOQUEAREXAMES: "Bloqueo de exámenes seleccionados",
        DESBLOQUEAREXAMES: "Desbloquear los exámenes seleccionados",
    },
    PACIENTE: {
        NOME: "Nombre",
        NOMESOCIAL: "Nombre Social",
        FONE: "Teléfono",
        CELULAR: "Teléfono móvil",
        SEXO: "Sexo",
        SELECIONARSEXO: "Selecciona tu sexo",
        FEM: "Femenino",
        MASC: "Masculino",
        NASCIMENTO: "Nacimiento",
        IDADE: "Edad",
        CALCULARIDADE: "Calcular la edad en",
        ANOS: "Años",
        MESES: "Meses",
        DIAS: "Días",
        PESO: "Peso (kg)",
        ALTURA: "Estatura (cm)",
        EMAIL: "Correo electrónico",
        RG: "Identificación.",
        CPF: "Seguro social",
        INSCRICAO: "Inscripción",
        NOMEMAE: "Nombre de la madre",
        RACA: "Raza",
        ESPECIE: "Especie",
        RESPONSAVEL: "Responsable",
        OBSERVACOES: "Observaciones",
        USUARIO: "Usuario",
        CAMERA: "Cámara",
        CAMERAMENSAGEM: "Haga clic para capturar a través de la cámara web",
        ARQUIVO: "Archivo",
        USUARIOWEB: "Crear usuario web",
        CANCELARUSUARIO: "Cancelar usuario web",
        INDICACAO: "Indicación",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MENSAGEMNASCIMENTO: "Valor no válido para la fecha de nacimiento.",
        MENSAGEMIDADE: "Valor inválido para la edad!",
        EMAILERRO: "El correo electrónico ",
        USADO: " ya está siendo utilizado por el usuario del ",
        CODIGOUSADO: " código ",
        RACAOBRIGATORIO: "Raza obligatoria.",
        ESPECIEOBRIGATORIO: "Especie obligatoria.",
        ESPECIEINVALIDA: "Especie no válida",
        RESPONSAVELOBRIGATORIO: "Responsable obligatorio",
        CPFOBRIGATORIO: "Seguro social o pasaporte obligatorio",
        USARIOWEBNAOCRIADO: "No es posible crear un usuario web sin correo electrónico.",
        CPFUSADO: "Este seguro social ya está en uso.",
        ERROSALVAR: "Error al guardar el paciente, verificando el seguro social: ",
        ERROSALVARPACIENTE: "Error al guardar el paciente: ",
    },
    BUSCACEP: {
        CEP: "Código postal",
        CEPMESSAGEM: "Código postal no encontrado",
        ENDERECO: "Dirección",
        BAIRRO: "Barrio",
        NUMERO: "Número",
        COMPLEMENTO: "Complemento"
    },
    BUSCACIDADE: {
        CIDADE: "Ciudad",
        NENHUMACIDADE: "Ninguna ciudad seleccionada",
    },
    EXAMESATRASADOS:{
        PEDIDO: "Solicitud", 
        REFERENCIA: "Referencia",
        EXAMES: "Exámenes",
        MOTIVO: "Razón",
    },
    INTEGRACAO: {
        TIPO: "Tipo",
        INTEGRADOR: "Integrador",
        DATA: "Fecha",
        MENSAGEM: "Mensaje",
    },
    WORKFLOW: {
        DATARETORNO: "Fecha de regresso",
        ETIQUETA: "Etiqueta",
        IMPRIMIRETIQUETA: "Imprimir la etiqueta",
        COMPROVANTE: "Bono",
        IMPRIMIRCOMPROVANTE: "Imprimir bono",
        VINCULARETIQUETA: "Vinculación de las etiquetas de precolección",
        COLETAEXTERNAPORTAL: "Registro de Coleta de Amostras",
        SALVAR: "Guardar",
        MENSAGEMVINCULO: "Enlace(s) completado(s) con éxito.",
        ERROIMPRIMIR: "Error al imprimir etiquetas: ",
        CAMPOSOBRIGATORIOS: "Data de Coleta é Obrigatória",
    },
    COMPROVANTEVARIAVEL:{
        VERPDF: "Ver PDF",
        IMPRIMIR: "Imprimir",
        CANCELAR: "Cancelar",
        MENSAGEMIMPRESSAO: "Error al imprimir el bono: "
    },
    MODALUSUARIOCONVENIO: {
        CONFIGUSUARIO: "Configuración del usuario",
        VIDEOS: "Vídeos de formación",
        AGENTE: "Agente de impresión",
        ALTERARSENHA: "Cambiar contraseña",
        SAIRSISTEMA: "Salir del sistema",
        FECHAR: "Cerrar",
    },
    MODALUSUARIO: {
        TITULO: "Configuración del Usuario",
        GUICHE: "Ventanilla",
        GUICHEPLACEHOLDER: "Identificación de la ventanilla",
        ETIQUETAPADRAO: "Etiqueta Predeterminada",
        ETIQUETAPADRAOPLACEHOLDER: "Plantilla de Etiqueta",
        ALTERARSENHA: "Cambiar Contraseña",
        LINKSUTEIS: "Enlaces Útiles:",
        SUPORTEREMOTO: "Soporte Remoto",
        VIDEOS: "Vídeos de Formación",
        AGENTEIMPRESSAO: "Agente de Impresión",
        FECHAR: "Cerrar",
        SALVAR: "Guardar"
    },
    MODALIMPRESSAO: {
        IMPRESSAO: "Imprimir",
        CONEXAO: "Conexión",
        IMPRIMIR: "Impreso",
        FECHAR: "Cerrar",
        SALVAR: "Guardar",
    },
    MODALCANCELANDOEXAME: {
        CANCELAREXAME: "Cancelar examen",
        ATENCAO: "Atención!",
        AVISOCANCELAMENTO: "Este procedimiento cancelará los exámenes seleccionados",
        EXAMES: "Exámenes",
        MOTIVOS: "Razones",
        CONFIRMARCANCELAMENTO: "Confirmar cancelación",
        CANCELAR: "Cancelar",
    },
    MODALCANCELANDOAGENDA: {
        CANCELARAGENDA: "Cancelar agenda",
        ATENCAO: "Atención!",
        AVISOCANCELAMENTO: "Este procedimiento cancelará la agenda",        
        MOTIVOS: "Razones",
        CONFIRMARCANCELAMENTO: "Confirmar cancelación",
        CANCELAR: "Cancelar",
    },    
    MODALALTERACAOAGENDA: {
        ALTERARAGENDA: "Alterar agenda",
        ATENCAO: "Atención!",
        AVISOALTERACAO: "Este procedimento irá alterar a data do agendamento",        
        MOTIVOS: "Razones",
        CONFIRMARALTERACAO: "Confirmar alteração",
        CANCELAR: "Cancelar",
    },              
    MODALRESPOSTAPESQUISA: {
        NOMEARQUIVO: "Nombre del archivo",
        CLIQUEDOWNLOAD: "Haga clic para descargar el archivo",
        BAIXARARQUIVO: "Descargar archivo",
        ALTERARARQUIVO: "Alterar el archivo",
        SALVAR: "Guardar búsqueda",
    },
    GUIAEXAME: {
        RESULTADOVINCULADO: "Resultado del examen vinculado al ítem ",
        GUIAVINCULADO: " de la guía ",
        EXCLUIREXAME: "Excluir el examen",
        LIGADESLIGAURGENCIA: "Activar/desactivar la urgencia",
        LIGADESLIGAMATERIAL: "Activar/desactivar la recepción del material",
        AUTORIZACAOCONVENIO: "Este examen requiere autorización del convenio!"
    },
    CADASTROGENERICO: {
        NENHUMEXAME: "No se encontró ningún examen",
        TOTAL: "Total"
    },
    EQUIPAMENTOS: {
        RESULTADOACONFIRMAR: "Resultados por confirmar:",
        CONFIRMARRESULTADO: "Confirmar el resultado",
        VINCULARAMOSTRA: "Vincular muestra",
        CONFIGEQUIPAMENTO: "Configurar el equipo",
        ENVIAWORKLIST: "Envía worklist",
        MANUTENCAOEQUIP: "Mantenimiento de los equipos",
    },
    CONFIRMARINTERFACE: {
        BUSCARGUIA: "Guía de búsqueda",
        SELECIONAR: "Seleccionar todo",
        DESMARCAR: "Deseleccionar todo",
        GUIA: "Guía",
        AMOSTRA: "Muestra",
        VALORCRITICO: "Fuera del valor crítico",
        RESULTADOANTERIOR: "Resultados anteriores",
        EDITARRESULTADO: "Editar los resultados",
        CANCELAR: "Cancelar",
        SALVAR: "Guardar",
        GRAFICO: "Gráfico",
        RESULTADOCONFIRMADO: "El resultado se confirmó con éxito",
        MEDICAMENTOS: "Medicamentos: ",
        RESULTADOREPETIDO: "Resultado",
        REPETIDO: "repetido",
        INTERFACE: "Interfaz",
        CONFIRMACAO: "Confirmación",
        DELETANDO: "El resultado no fue posible repetirse. Consulta la consola para obtener más información.",
        PROCURARGUIA: "Buscar guía",
        GUIANAOENCONTRADA: "Guía no encontrada: ",
        MENSAGEMDELETANDO: "¿Estás seguro de que quieres  <b>borrar</b> este resultado? Esta acción no se puede deshacer.<br/><b>",
        EXCLUIRRESULTADO: "Eliminar el resultado",
        EXCLUIR: "Borrar",
        RESULTADO: "Resultado",
        EXCLUIDO: "Borrado",
        MESSAGEERROEXCLUIR: "No fue posible borrar el resultado. Consulta la consola para obtener más información.",
        MENSAGEMNAOALTERAR: "El resultado no ha podido ser modificado: no se ha introducido ningún valor.",
        ALTERADO: "cambiado a: ",
        MENSAGEMRESULTADOS: "Los resultados se están confirmando, este proceso puede durar unos minutos.",
    },
    MODALBLOQUEIO: {
        AVISOMOTIVO: "Indique el motivo del bloqueo",
        MOTIVO: "Razones",
        BLOQUEAREXAME: "Bloquear examen",
        BLOQUEARHORARIOS: "Bloquear Horários",
        ATENCAO: "Atención",
        NOTIFICAOBLOQ: "Esto bloqueará los exámenes seleccionados.",
        EXAMES: "Exámenes",
        MOTIVOS: "Razones",
        CANCELAR: "Cancelar",
    },
    SAMPLELINK: {
        INTERFACE: "Interfaz",
        VINCULO: "Enlace de muestra",
        TITULOVINCULAR: "Vincular muestra",
        CONFIRMAVINCULAR: "Confirmar la relación entre los resultados y la muestra?",
        CODIGO: "Código de barras",
        CANCELAR: "Cancelar",
        EXCLUIR: "Borrar resultado",
        CONFIRMAEXCLUIR: "¿Estás seguro de que quieres <b>borrar</b> este resultado? Esta acción no se puede deshacer.",
        AMOSTRAREMOVIDA: "Muestra eliminada",
        AMOSTRAVINCULADA: "Muestra enlazada",
    },
    MODALCOPIAREXAMES: {
        EQUIPAMENTOS: "Equipos",
        COPIARCONFIGS: "Copiar configuraciones",
        ATENCAO: "Atención",
        ATENCAOAVISO: "Esta acción copiará las configuraciones de los equipos seleccionados.",
        CONFIRMAR: "Confirmar",
        CANCELAR: "Cancelar",
    },
    CONFIG: {
        BITSEGUNDO: "Bits/segundo",
        ATENCAO: "Atención",
        NOTIFICACAO: "Los cambios sólo tendrán efecto la próxima vez que la interfaz se conecte al equipo.",
        NOME: "Nombre",
        INSTALACAO: "Instalación",
        VERSAO: "Versión",
        TIPOCONEXAO: "Tipo de conexión",
        PORTA: "Puerta",
        BITSSEGUNDO: "Bits/segundos",
        PARIDADE: "Paridad",
        BITSDADOS: "Bits de datos",
        BITSPARADA: "Bits de parada",
        CONTROLEFLUXO: "Control de flujo",
        DIRETORIOIMPORTACAO: "Directorio de importación",
        DIRETORIOEXPORTACAO: "Directorio de exportación",
        ENVIARESULTADOUNIDADE: "Enviar el resultado con la unidad recibida",
        ENVIARTODOS: "Enviar automáticamente <b>TODOS</b> los resultados(sin confirmación)",
        NAOSOBREPOR: "No superponer los resultados ya firmados",
        SEMAMOSTRAVINCULADA: "El equipo envía los resultados sin que la muestra esté ligada",
        ENVIARESULTADONORMAL: "Enviar automáticamente los resultados con el valor normal:",
        VALORNORMAL: "Valor normal",
        APENASMATERIALENTREGUE: "Envíe sólo las pruebas con el material entregado",
        REENVIAR: "Reenviar sólo los exámenes pendientes de mecanografía",
        REENVIARTODOS: "Reenviar todos los exámenes de la muestra",
        OCULTARCOMENTARIOS: "No mostrar los comentarios recibidos en la confirmación",
        IGNORAREMBRANCO: "Ignorar los resultados en blanco",
        IGNORARREPETIR: "Ignorar y repetir (de nuevo a la espera) los resultados con valor:",
        VALORREPETIR: "Repetir valor",
        TOTALIZADOR: "Totalizador:",
        TITULO: "Título",
        FORMULA: "Fórmula",
        EXAMES: "Exámenes",
        CODIGO: "Código",
        CODIGOEXAME: "Código de examen",
        NOMEEXAME: "Nombre del examen",
        NENHUMEXAME: "No se han encontrado exámenes",
        CODIGOCAMPO: "Código del campo",
        NOMECAMPO: "Nombre del campo",
        NENHUMCAMPO: "No se han encontrado campos",
        APELIDOEXAME: "Apellido del examen",
        VERSAOMASCARA: "Versión de la máscara",
        AMOSTRANUMERO: "Número de la muestra",
        ORDENACAO: "Ordenación",
        DECIMAIS: "Decimales",
        UNIDADE: "Unidad",
        FORMULACALCULO: "Fórmula para el cálculo",
        SORECEBE: "Sólo recibe",
        ENVIAASSINADO: "Envíe firmado",
        MATERIAIS: "Materiales",
        NOMEMATERIAL: "Nombre del material",
        NENHUMMATERIAL: "No se ha encontrado material",
        INCLUIRMATERIAL: "Incluir el material",
        INCUIREXAME: "Incluir el examen",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        INTERFACE: "Interfaz",
        CONFIGURACAO: "Configuración",
        NENHUMA: "Ninguna",
        ESPACO: "Espacio",
        IMPAR: "Ímpar",
        PAR: "Par",
        MARCA: "Marca",
        ERROSALVAR: "Error al guardar la configuración del equipo: ",
    },
    HISTORICO: {
        FECHAR: "Cerrar",
        TITULO: "Historial de cambios",
        INFORMACOES: "Información",
    },
    PROGRESSOBANCADA: {
        PROGRESSOBANCADA: "Progreso por bancada",
        UNIDADE: "Unidad",
        ATUALIZAR: "Actualizar",
        EXAMESNAOECONTRADOS: "No se encontraron exámenes con el filtro seleccionado",
        ERRORVISUALIZARBANCADA: "¡Error! No se puede ver el bancada",
    },
    PROGRESSOSETOR: {
        PROGRESSOSETOR: "Progreso por sector",
        UNIDADE: "Unidad",
        ATUALIZAR: "Actualizar",
        EXAMESSEMMATERIALENTREGUE: "Contabilización de exámenes sin material entregado con retraso",
        EXAMESNAOECONTRADOS: "No se encontraron exámenes con el filtro seleccionado",
    },
    CHECKPOINT: {
        VOLTAR: "Volver",
        ORDEMLOTE: "Pedido por lotes",
        DATASOLICITACAO: "Fecha de solicitud",
        NOMEPACIENTE: "Nombre del paciente",
        SEPARAREXAMES: "Exámenes por página",
        SEPARARLOCAIS: "Ubicación por página",
        SEPARARSETORES: "Sectores por página",
        EXIBEOBSPACIENTE: "Ver observación del paciente",
        INCLUIRESPACOASSINATURA: "Incluir espacio para las firmas de los responsables",
        EXIBEULTIMOSRESULTADOS: "Muestra los últimos resultados",
        EXIBEINSCRICAO: "Muestra la fecha de inscripción del paciente",
        EXIBEDATARETORNO: "Muestra la fecha de retorno del paciente",
        EXIBECONSERVANTE: "Muestra el conservante",
        EXIBEMEDICO: "Muestra el nombre del solicitante",
        SOMENTESEMDIGITAÇÃO: "Sólo exámenes sin resultados escritos",
        IMPRIMERASCUNHO: "Imprime un borrador con los campos de resultados",
        EXIBECODIGOBARRAS: "Ver ejemplos de códigos de barras",
        EXIBEPAPELRAZAO: "Emisiones en papel de contabilidad (A5)",
        EXIBELEITO: "Mostrar el número de cama del paciente",
        EXIBEDATANASCIMENTO: "Muestra la fecha de nacimiento del paciente",
        EXIBELOCAL: "Muestra el nombre de la ubicación de origen",
        EXIBEMATERIAL: "Visualización del material",
        EXIBECONVENIO: "Muestra el nombre del Seguro",
        EXAMESURGENTES: "Solo exámenes urgentes",
        STATUSEXAMEENVIADO: "Escribe el estado del examen como enviado",
        PROCURAREXAME: "Buscar examen",
        ENVIARINTEGRADOR: "Enviar al integrador",
        UNIDADE: "Unidad",
        EXAME: "Examen",
        LOCALORIGEM: "Local de Origen",
        CONVENIO: "Seguro de Salud",
        EMISSAOBANCADA: "Emisión de banco de trabajo",
        ERRO: "Error",
        FECHARMESAGEM: "Cerrar mensaje",
        SETOR: "Sector",
        INCLUISETOR: "Incluir el sector",
        INCLUICONVENIO: "Incluya un seguro de salud",
        CONTADOR: "Contable",
        AMOSTRA: "Muestra",
        GUIA: "Guía",
        MATERIAL: "Material",
        CONSERVANTE: "Conservador",
        EXAMES: "Exámenes",
        INCIDENCIA: "Incidencia",
        ACEITARAMOSTRA: "Aceptar muestra",
        ACEITARRESTRICAO: "Aceptar muestra (Con restricción)",
        NAOACEITARAMOSTRA: "No aceptar (Rechazo)",
        CODIGOBARRAS: "Código de Barras",
        INCLUIR: "Incluir",
        CODIGOGUIA: "Código Guía",
        PENDENCIA: "Pendiente",
        LOTEAMOSTRA: "Lote de muestras",
        FUNCIONARIORESP: "Funcionario responsable: ",
        IDENTIFICADOR: "Identificador",
        TEMPERATURA: "Temperatura (C°)",
        UMIDADE:"Humedad (%)",
        LATITUDE: "Latitud",
        LONGITUDE: "Longitud",
        EMITEBANCADA: "Banca de emisión",
        GERAEXTRACAO: "Generar extracto",
        ENVIAINTEGRADOR: "Enviar al integrador",
        INTEGRADOR: "Integrador",
        IMPRIMIR: "Imprimir",
        IMPRIMIRALIQUOTA: "Imprimir alícuotas",
        IMPRIMIREXTRACAO: "Imprimir extractos",
        CANCELAR: "Cancelar",
        ATENDIMENTO: "Asistencia",
        LOTEAMOSTRAS: "Lote de Muestras",
        CHECKPOINT: "Checkpoint",
        BANDEJA: "Bandeja",
        AVISOBANDEJA: "Posições insuficientes na bandeja para inserção da amostra",
        AMOSTRASELECIONADA: "Muestra ya seleccionada",
        AMOSTRAJAINCLUIDA: "La muestra {msg} ya está incluida.",
        MENSAGEMFILTROSETOR: "La muestra no respeta el filtro Sector.",
        MENSAGEMFILTROCONVENIO: "La muestra no respeta el filtro Seguro de salud.",
        AMOSTRANAOENCONTRADA: "Muestra no encontrada. Comprueba el contenido buscado y vuelve a intentarlo.",
        AMOSTRANAOSELECIONADA: "No se ha seleccionado ninguna muestra.",
        AMOSTRASENVIADAS: "Muestras enviadas. El PDF del banca puede tardar unos minutos en generarse, se mostrará en una nueva ficha.",
        CODIGOGUIANAOENCONTRADO: "Guía no encontrada. Verifique el contenido buscado y vuelva a intentarlo.",
        LOTENAOENCONTRADO: "Lote no encontrado. Verifique el contenido buscado y vuelva a intentarlo.",
        SEMDESCRICAO: "Descripción requerida. Introduzca una descripción para la muestra!",
        EFETUADOCHECKPOINT: "Punto de control ya realizado para esta muestra",
        DESCRICAO: "Descripción",
        SOLICITARNOVAAMOSTRA: "Nueva Muestra",
        ALIQUOTAAMOSTRA: "Alícuota",
        AMOSTRASTATUS: "Status",
        ALTERARSTATUS: " El cambio de estado en el lote sólo se permite para las muestras con el mismo estado o sin estado establecido"
    },
    MANUTENCAOLISTA: {
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGINAATUAL: "Página actual",
        NOMETECNICO: "Nombre técnico",
        DATAAGENDADA: "Fecha prevista",
        FUNCIONARIO: "Empleado",
        EDITAR: "Editar",
        INTERFACE: "Interfaz",
        MANUTENCOES: "Mantenimiento",
    },
    MANUTENCAO: {
        MENSAGEMSALVAR: "El mantenimiento se está guardando, este proceso puede tardar unos segundos.",
        NOMETECNICO: "Nombre técnico",
        DATAAGENDADA: "Fecha prevista",
        FUNCIONARIO: "Empleado",
        DESCRICAO: "Descripción",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        INTERFACE: "Interfaz",
        MANUTENCOES: "Mantenimiento",
    },
    ATENDIMENTO: { 
        CONSULTARGUIA: "Consultar guías",
        CODIGO: "Código",
        REFERENCIA: "Referencia",
        AMOSTRA: "Muestra",
        PROCURARGUIA: "Búsqueda por código de guía",
        PROCURARREERENCIA: "Búsqueda por referencia de guía",
        PROCURARAMOSTRA: "Búsqueda por código de muestra",
        LISTARGUIAS: "Lista de todas las guías",
        INCLUIRGUIA: "Incluir nueva guía",
        NOMEPACIENTE: "Introduzca el nombre del paciente",
        PROCURARPACIENTE: "Buscar pacientes por nombre/DNI/registro/fecha de nacimiento",
        INSCRICAO: "Inscripción: ",
        RESPONSAVEL: "Responsable: ",
        AGENDA: "Agenda",
        PACIENTESAGENDADOS: "Pacientes programados para hoy",
        NENHUMPACIENTEAGENDADO: "No hay pacientes programados para hoy",
        COLETADOMICILIAR: "Haga clic para ver la ruta de recogida a domicilio de estos pacientes",        
        ABRIRCALENDARIO: "Calendario",
        INSPECAO: "Inspección",        
        COLETAMATERIAIS: "Recolecta de exámenes",
        LISTARTODOSAGENDAMENTOS: "Lista de todos los nombramientos",
        AGENDAMENTOSHOJE: "Lista de nombramientos para hoy",
        RECEBIMENTOAMOSTRA: "Recepción de muestras",
        PENDENCIAS: "Lista de pendientes",
        QUANTIDADEPACIENTES: "Número de pacientes en espera de recogida por centro: ",
        NENHUMPACIENTES: "Ningún paciente en la espera de recoger material ",
        ULTIMASGUIAS: "Últimas guías modificadas",
        MOSTRARULTIMAS: "Mostrar las últimas 5 guías",
        PROCURAEXAMES: "Búsqueda de exámenes",
        ORCAMENTO: "Presupuestos",
        PROCURAR: "Buscar en",
        LISTARTODOS: "Listar todo",
        INCLUIR: "Incluir",
        LOTEAMOSTRA: "Lote de muestras",
        LISTAR: "Lista",
        ENTREGARESULTADOS: "Entrega de los resultados",
        REGISTRAENTREGA: "Registro de la entrega",
        BUSCA: "Buscar en",
        MENSAGEMCARTERINHA: "Tarjeta solicitada.",
        ERROCARTERINHA: "Error al enviar la tarjeta: ",
        GUIA: "Guía",
        NAOENCONTRADA: "No se ha encontrado",
        GUIAREFERENCIA: "Guía con referencia",
        GUIAAMOSTRA: "Muestra",
        SENHAS: "Contraseñas de asistencia",
        SALAESPERA: "Sala de Espera",
        SALAESPERALISTAR: "Listar Todo",           
        SALAESPERAAGUARDANDO: "Cantidad de pacientes aguardando en la sala de espera",
        ESPECIE: "Especie",
        RACA: "Raza",
        AGENDAMENTO: "Cita",
    },
    GUIALIST: {
        BUSCARGUIAS: "Nombre/Id/DNI/Fecha de nacimiento",
        BUSCARGUIASNOME: "Nome",
        REFERENCIA: "Referencia",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGINAATUAL: "Página actual",
        LOCALORIGEM: "Lugar de origen",
        EMISSAO: "Emitido en",
        ATENDIMENTO: "Asistencia",
        GUIA: "Guías",
    },
    AGENDA: {
        MENSAGEMREAGENDAMENTOBOTAO: "Cancela Reprogramar?",
        MENSAGEMREAGENDAMENTO: "Estás realizando una reprograma",        
        MENSAGEMREAGENDAMENTOEVENTO: "Reagendamento de {0} na data {1} - Exame(s): {2}",
        RESPOSAVELFILTRO: "Filtrar por tipo de cita",
        ATENDIMENTO: "Asistencia",
        CONFIRMARREAGENDAR: "Desea reprogramar el procedimiento, la cita actual será cancelada",
        SELECIONEEXECUTANTE: "seleccione el ejecutante",
        EXECUTANTE: "Ejecutante",
        ATENCAO: "Atención!",        
        AGENDA: "Agenda",
        MENSAL: "Mensual",
        DIARIO: "Diario",
        INCLUIRPACIENTE: "Incluir Paciente",
        BLOQUEARHORARIO: "Bloque de Tiempo",
        DESBLOQUEARHORARIO: "Tiempo de desbloqueo",    
        HORARIOLIVRE: "Tiempo libre",        
        HORARIOBLOQUEADO: "Tiempo Bloqueado",  
        CONFIRMACANCELAMENTO: "Confirma la cancelación de esta agenda?",  
        CONFIRMAPACIENTE: "Confirmar o cronograma?", 
        ERROAOSALVAR: "Error al guardar la agenda, verifique la hora e intente nuevamente",  
        VERGUIAPACIENTE: "Ver guías do paciente",
        CRIARGUIA: "Criar guía",
        ADICIONAREVENTO: "Añadir evento",         
        ESCALASNAOCADASTRADAS: "Não existem horários livres para este dia!",
        PACIENTENAOCONFIRMADO: "Paciente Não confirmó!" ,
        PACIENTECONFIRMADO: "Paciente confirmó!",  
        PACIENTEENCAIXE: "Encaixe!",
        ERROSALVAR: "Erro ao salvar",
        NAOAGENDADO: "Não Agendado",    
        HORARIORESERVADO: "Tiempo reservado", 
        EXAMELABORATORIAL: "Examen de laboratorio?",
        IRPARADATA: "ir a fecha",         
        NENHUMEXAMESELECIONADO: "Ningún examen seleccionado",
        CONVENIOOBRIGATORIO: "Seguro obligatorio",
        NENHUMHORARIOSELECIONADO: "Sin tiempo seleccionado",
        EXAMESNAOAGENDADOS: "Hay exámenes aún no programados",  
        EXAMESJAAGENDADOS: "Examen(s) ja programado",        
        NAOEXISTEHORARIOLIVRE: "No hay tiempos libres para hacer cronograma",        
        MENSAGEMEXAMELABORATORIAL: "Exámenes que no poseen equipos vinculados",
        OBSERVACOESGUIA: "Observacións de la guia",
        ENCAIXE:"Encaixe",
        AGENDAREXAMES: "Programar examenes"
    },
    AGENDAMODAL: {
        ERRO: "Error",
        MENSAGEMFECHAR: "Cerrar mensaje",
        DATAHORA: "Fecha y hora",
        SELECIONARHORA: "Seleccione una hora",
        NOVOPACIENTE: "Agregar un nuevo paciente",
        TIPOAGENDAMENTO: "Tipo de nombramiento",
        NOVOSTATUS: "Agregar un nuevo estado",
        FUNCIONARIORESPONSAVEL: "Ejecutante",
        NENHUM: "Ninguna",
        OBSERVACAO: "Observación",
        CONFIRMAR: "Confirmar",
        CANCELAR: "Cancelar",
        MENSAGEMPACIENTE: "El paciente debe ser informado",
        MENSAGEMTIPOAGENDAMENTO: "Es necesario informar del tipo de nombramiento",
        MENSAGEMDATAHORA: "La fecha/hora no puede ser inferior a la fecha/hora actual",
        RESPONSAVELOCUPADO: "Responsable ocupado",
        RESPONSAVELOCUPADOMENSAGEM: "La persona responsable ya tiene una cita en este momento",
        HORARIOPREENCHIDO: "Horas cubiertas",
        MENSAGEMHORARIOPREENCHIDO: "Puede confirmar la inclusión de una cita al mismo tiempo que otro paciente?",
        ESPECIE: "Especie",
        RACA: "Raza",
        RESPONSAVEL: "Responsable",
    },
    AGENDARELACAO: {
        SELECIONEPERIODO: "Seleccione el periodo",
        FILTRAR: "Filtro",
        FILTRARRESPONSAVEL: "Filtrar por empleado responsable",
        FILTRARAGENDAMENTO: "Filtrar por tipo de cita",
        FILTRARSTATUS: "Filtrar por status",
        DATA: "Fecha",
        ENDERECO: "Dirección",
        BAIRRO: "Vecindario",
        CEP: "CEP",
        CIDADE: "Ciudad",
        FONE: "Teléfono",
        OBSERVACAO: "Observación",
        TIPOAGENDAMENTO: "Tipo de nombramiento",
        FUNCIONARIORESPONSAVEL: "Funcionario responsable",
        ATENDIMENTO: "Asistencia",
        RELACAOAGENDAMENTO: "Relação de Agendamento",
        HISTORICO: "Ver Histórico",
        BUSCAPACIENTE: "Buscar Paciente",
        PROCURAR: "Buscar enmbre",
        ABRIRGUIA: "Guia",
        CRIARGUIA: "Criar guia",
        EXAMES: "Exames",
        EXIBECONVENIO: "Ver convenio",
        CONVENIO: "Convenio",
    },
    SALAESPERA: {
        ATUALIZAR: "Actualizar",
        SELECIONEPERIODO: "Seleccione el periodo",
        FILTRAR: "Filtro",
        NUMEROGUIA: "Número de guía",
        FILTRARRESPONSAVEL: "Filtrar por empleado responsable",
        FILTRARAGENDAMENTO: "Filtrar por tipo de cita",
        FILTRARSTATUS: "Filtrar por estado",
        DATA: "Fecha",
        STATUS: "Status",
        IDADE: "Edad",
        OBSERVACAO: "Observación",
        TIPOAGENDAMENTO: "Tipo de examen",
        FUNCIONARIORESPONSAVEL: "Funcionario responsable",
        ATENDIMENTO: "Asistencia",
        SALAESPERA: "Sala de Espera",
        ORDEM: "Orden",
        RELACAOAGENDAMENTO: "Relação de Agendamento",
        HISTORICO: "Ver Histórico",
        CHECKOUT: "Checkout",
        FAZERCHECKOUT: "Hacer Checkout",
        REALIZARATENDIMENTO: "Realizar Asistencia",
        EXECUTANTE: "Ejecutante",
        SALVANDODADOS: "Guardar Ejecutante",
        ACAO: "Información",
        VALORPAGO: "Importe pagado",
        PESQUISA: "Investigaciones",
    },        
    COLETA: {
        LOCALORIGEM: "Local de origen",
        SETOR: "Sector",
        PERIODO: "Periodo",
        RESULTADOSPORPAGIA: "Resultados por página",
        ORDENARPACIENTE: "Ordenar por nombre de paciente",
        FILTRAURGENTES: "Filtrar sólo los exámenes urgentes",
        FILTRAR: "Filtro",
        GUIA: "Guía",
        PROXPAGINA: "Página siguiente",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGINAATUAL: "Página actual",
        EXAMEURGENTE: "Examen urgente",
        FUNCIONARIORESPONSAVEL: "Funcionario responsable",
        CANCELAR: "Cancelar",
        ATENDIMENTO:"Asistencia",
        COLETA: "Recogida por exámenes",
        MENSAGEMCOLETA: "Es necesario seleccionar al menos una guía para su presentación",
    },
    RECEBIMENTOAMOSTRAS: {
        AMOSTRA: "Muestra",
        MOTIVO: "Seleccione el razón",
        GUIA: "Guía",
        DATAEMISSAO: "Fecha de emisión",
        MATERIAL: "Materiales",
        CONSERVANTE: "Conservador",
        SETOR: "Sector",
        EXAMES: "Exámenes",
        ACONDICIONAMENTOCONGELADA: "Acondicionamiento: Congelado",
        ACONDICIONAMENTOREFRIGERADA: "Acondicionamiento: Refrigerado",
        ACONDICIONAMENTOAMBIENTE: "Acondicionamiento: Ambiente",
        ACEITARAMOSTRA: "Aceptar muestra",
        ACEITARAMOSTRARESTRICAO: "Aceptar muestra (Con restricción)",
        REJEITARAMOSTRA: "No aceptar (Rechazo)",
        FILTROSEMRESULTADO: "No hay muestras que coincidan con el filtro seleccionado !",
        SOMENTEMATERIALENTREGUE: "Sólo se mostrarán los exámenes en los que no se haya entregado el material. Comprueba el contenido buscado y vuelve a intentarlo.",
        AMOSTRARECEBIDA: "Se están recibiendo muestras.",
        CODIGOBARRAS: "Código de Barras",
        INCLUIR: "Incluir",
        REFERENCIA: "Referencia",
        SELECIONACONVENIO: "Seleccione el seguro de salud",
        LOTEAMOSTRAS: "Lote de muestras",
        FUNCIONARIORESPONSAVEL: "Funcionario responsable: ",
        IDENTIFICADOR: "Identificador",
        TEMPERATURA: "Temperatura (C°)",
        LATITUDE: "Latitud",
        LONGITUDE: "Longitud",
        IMPRIMIR: "Imprimir",
        LIMPAR: "Limpiar",
        CANCELAR:  "Cancelar",
        ATENDIMENTO:"Asistencia",
        RECEBIMENTO: "Recepción de muestras",
        AMOSTRASELECIONADA: "Muestra ya seleccionada",
        MENSAGEMAMOSTRA: "La muestra ",
        AMOSTRAINCLUIDA: "ya incluidos en la lista.",
        AMOSTRANAOPERTENCE: "Esta muestra no pertenece al paciente referenciado",
        ERROINFO: "Error en la búsqueda de información",
        MOTIVOOBRIGATORIO: "Razón obligatorio de recepción.",
        IDENTIFICACAO: "Identificación obligatoria de los empleados!",
        IDENTIFICACAOPACIENTE: "Identificación obligatoria del paciente",
        CONSERVANTES: "Seleccione el conservante",
        SELECIONEDOCUMENTO: "Seleccione un documento para enviar",
    },
    ORCAMENTOLISTA: {
        PROCURARNOME: "Buscar enmbre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGINAATUAL: "Página actual",
        ID: "Id",
        FUNCIONARIO: "Empleado",
        EMISSAO: "Emitido en",
        VALIDADE: "Validez",
        VALOR: "Valor (S/.)",
        EXPIRADO: "Expirado",
        ATENDIMENTO:"Asistencia",
        ORCAMENTOS: "Presupuestos",
        EDITAR: "Editar",
    },
    ORCAMENTOCADASTRO: {
        DESCONTO: "Descuento",
        INCLUIRPACIENTE: "Agregar un nuevo paciente",
        INCLUIRMEDICO: "Agregar un nuevo solicitante",
        DATAEMISSAO:  "Fecha de emisión",
        DATAVALIDADE: "Fecha de validación",
        EXAME: "Exámenes",
        INCLUIREXAME: "Incluir examen",
        NENHUMEXAME: "No se han encontrado exámenes",
        MATERIAL: "Material",
        CONSERVANTE: "Conservador",
        CODIGO: "Código",
        APELIDO: "Apellido",
        NOME: "Nombre",
        GRUPOEXAME: "Grupo de examen",
        PRECO: "Precio",
        TOTALEXAMES: "Total de exámenes",
        OBSERVACOES: "Observaciones",
        IMPRIME: "Imprima",
        ENVIAREMAIL: "Enviar por correo electrónico",
        ORCAMENTOEXPIRADO: "Presupuesto expirado",
        CRIARGUIA: "Crear una guía",
        SALVAR: "Guardar",
        CANCELAR:  "Cancelar",
        ATENDIMENTO: "Asistencia",
        ORCAMENTO: "Presupuesto",
        PACIENTEOBRIGATORIO: "Paciente obligatorio",
        INFORMARPACIENTE: "Especifique un paciente para continuar.",
        ERROSALVAR: "Error al guardar el presupuesto: ",
        TITULOENVIAREMAIL: "Enviar el presupuesto por correo electrónico",
        EMAIL: "Correo electrónico",
        EXAMETITULO: "Examen ya incluido",
        MENSAGEMCONFIRMACAO: "Confirma la inclusión del examen: <b>",
    },
    PROCURAEXAME: {
        UNIDADE: "Unidad",
        NUMEROGUIA: "Número de guía",
        LOCAL: "Local",
        RESPONSAVEL: "Responsable",
        SETOR: "Sector",
        EXAME: "Examen",
        EXAMESTATUS: "Estado del examen",
        SELECIONESTATUS: "Seleccione el estado del examen",
        EXAMEATRASADO: "Exámenes atrasados",
        EMISSAO: "Emitido",
        SOLICITACAO: "Solicitado",
        COLETA: "Recolectado",
        RETORNO: "Retorno",
        ENTREGA: "Entrega",
        RESULTADOPAGINA: "Resultados por página",
        EXAMES: "Exámenes",
        ALTERARPRAZO: "Cambiar el plazo",
        PROXPAGINA: "Página siguiente",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGINAATUAL: "Página actual",
        PACIENTENOME: "Nombre del paciente",
        APELIDO: "Apellido",
        DATAENTREGA: "Fecha de entrega",
        OBSERVACOES: "Observaciones",
        STATUS: "Estado",
        ATENDIMENTO: "Asistencia",
        PROCURAEXAME: "Búsqueda de exámenes",
    },
    ENTREGARESULTADOS: {
        CODIGOGUIA: "Código de la guía",
        LOADING: "Cargando",
        INCLUIR: "Incluir",
        GUIA: "Guía",
        DATAEMISSAO: "Fecha de emisión",
        EXAMES: "Exámenes",
        ENTREGAR: "Entregar",
        CANCELAR: "Cancelar",
        ATENDIMENTO: "Asistencia",
        ENTREGARESULTADOS: "Entrega de los resultados",
        MENSAGEMDESTINO: "Destino de la entrega",
    },
    LISTALOTEAMOSTRA: {
        PROCURADESCRICAO: "Buscar por descripción",
        SELECIONAPERIODO: "Seleccione el periodo",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGINAATUAL: "Página actual",
        ID: "Id",
        DESCRICAO: "Descripción",
        ENVIO: "Envío",
        RECEBIMENTO: "Recepción",
        EDITAR: "Editar",
        ATENDIMENTO: "Asistencia",
        LOTEAMOSTRAS: "Lote de muestras",
        LISTAR: "Lista",
    },
    LOTEAMOSTRA: {
        NOME: "Nombre",
        TEMPERATURA: "Temperatura: ",
        UMIDADE: "Humedad: ",
        AMOSTRAS: "Muestras",
        SELECIONADAS: "seleccionadas",
        SELECIONARTODAS: "Seleccionar todo",
        DESSELECIONARTODAS: "Deseleccionar todo",
        IMPRIMEETIQUETA: "Impresión de etiquetas",
        ATENDIMENTO: "Asistencia",
        LOTE: "Lote de muestras",
        AMOSTRA: "Amostra",
        IMPRIMIRLOTE: "Impresión de lotes"
    },
    INCLUIRLOTEAMOSTRAS: {
        FECHARMENSAGEM: "Cerrar mensaje",
        AMOSTRA: "Muestra",
        AMOSTRANAOENCONTRADA: "Muestra no encontrada. Verifique el contenido buscado y vuelva a intentarlo.",
        AMOSTRANAOSELECIONADA: "¡Debe insertarse al menos una muestra!",
        CHECKBOXAMOSTRANAOSELECIONADO: "¡Debe seleccionar las muestras que desea enviar!",
        MATERIAL: "Material",
        CONSERVANTE: "Conservador",
        SETOR: "Sector",
        EXAMES: "Exámenes",
        ACONDICIONAMENTOCONGELADA: "Acondicionamiento: Congelado",
        ACONDICIONAMENTOREFRIGERADA: "Acondicionamiento: Refrigerado",
        ACONDICIONAMENTOAMBIENTE: "Acondicionamiento: Ambiente",
        DESCRICAO: "Descripción",
        CODIGOBARRAS: "Código de Barras",
        INCLUIR: "Incluir",
        TEMPERATURA: "Temperatura (C°)",
        LATITUDE: "Latitud",
        LONGITUDE: "Longitud",
        CANCELAR: "Cancelar",
        ATENDIMENTO: "Asistencia",
        LOTEAMOSTRAS: "Lote de muestras",
    },
    GUIAATENDIMENTO: {
        CONVENIOERRO: "Convenio no atendido en esta unidad",
        CID10: "Cid10",
        DESCRICAO: "Descripción",
        MATERIAL: "Material", 
        CONSERVANTE: "Conservante", 
        CANCELAREXAMEDEPERFIL: "Cancelar examen de perfil",
        MSGCANCELAREXAMEDEPERFIL: "Prueba de perfil, confirmar cancelación?",
        ESTUDOS: "Estudios",
        DESVINCULAREXAME: "Desconectar Exámenes",
        CONFIRMREPETIR: "Confirmar exámenes repetidos",
        CONFIRMDESVINCULAR: "Confirmar la eliminación del enlace del examenes",
        IDENTIFICACAO: "Identificación",
        REFERENCIA: "Referencia",
        PAGAMENTO: "Pago",
        REALIZADO: "Realizado",
        AUTORIZADO: "Autorizado",
        PENDENTE: "Pendiente",
        INCIDENCIA: "Guia com incidência",
        LOCALORIGEM: "Lugar de origen",
        SELECIONELOCALORIGEM: "Seleccione su lugar de origen",
        EMITIDA: "Emitido en",
        INFOPACIENTE: "Abrir la información del paciente",
        GUIASPACIENTE: "Lista de todas las guías para pacientes",
        LEITO: "Cama",
        INSCRICAO: "Inscripción: ",
        SEXO: "Sexo: ",
        NASCIMENTO: "Nacimiento: ",
        HORASJEJUM: "Horas de ayuno",
        CALCULARHORASJEJUM: "Haga clic para calcular las horas de ayuno",
        ALTA: "Alta",
        ABRIRINFORMACOES: "Abrir la información del solicitante",
        ABRIRINFORMACOESCONVENIO: "Abrir la información del seguro de salud",
        INCLUIRMEDICO: "Agregar un nuevo solicitante",
        RETORNO: "Retorno",
        UNIDADE: "Unidad: ",
        ALTERARUNIDADE: "Cambiar de unidad",
        TIPOAGENDAMENTO: "Tipo de programación: ",
        EXAMES: "Exámenes",
        AMOSTRAS: "Muestras",
        INCLUIREXAME: "Incluye el examen",
        PROCURARPOR: "Buscar por",
        APELIDO: "Apellido",
        NOME: "Nombre",
        CODIGOEXTERNO: "Código externo",
        NENHUMEXAMEDISPONIVEL: "No hay pruebas disponibles. Asegúrese de haber elegido su proveedor de seguro médico.",
        BLOQUEADO: "BLOQUEADO: ",
        ITEMBLOQUEADO: "Bloqueado",
        DATASOLICITACAO: "Cambiar la fecha de solicitud de los exámenes seleccionados",
        DATACOLETA: "Cambiar la fecha de colección de los exámenes seleccionados",
        EXAMEREPETIDO: "Repetición del examen",
        CANCELAREXAMES: "Cancelar los exámenes seleccionados",
        BLOQUEAREXAMES: "Bloque de exámenes seleccionados",
        DESBLOQUEAREXAMES: "Desbloquear los exámenes seleccionados",
        REPORTAREXAMES: "Informe de los exámenes",
        RECEBERMATERIAL: "Recepción del material de examen",
        ENTREGARESULTADOS: "Entrega de los resultados",
        UPLOADIMAGEM: "Adjuntar estudios",
        INFORMACOES: "Información",
        CANCELARAMOSTRAS: "Cancelar las muestras seleccionadas",
        CANCELARCOLETA: "Cancelar la recogida de la muestra seleccionada",
        RECEBERAMOSTRA: "Recepción de material de muestra",
        ARMAZENARSOROTECA: "Almacenar en la Soroteca",
        IMPRIMIRETIQUETA: "Imprimir la etiqueta",
        ALTERARCODIGO: "Cambiar el código de barras de la muestra seleccionada",
        REGISTRARINCIDENCIA: "Registro de la incidencia de la muestra",
        ENVIARAMOSTRA: "Enviar muestra para integración",
        REIMPRIMIRINTEGRACAO: "Reimpresión de la etiqueta de integración",
        SOLICITARNOVAAMOSTRA: "Solicitar una nueva muestra",
        INCLUIR: "Incluir",
        DIGITAR: "Escribe ",
        COMPROVANTE: "Recibo",
        GUIASP: "Guia SP/SADT",
        OUTRASDESPESAS: "Otros Gastos SADT",
        NOTIFICAR: "Notificar",
        ASSINAR: "Firmar",
        ETIQUETAS: "Etiquetas",
        RESULTADOS: "Resultados",
        VISUALIZAR: "Ver",
        IMPRIMIR: "Imprimir",
        EMAILMEDICO: "Correo electrónico al solicitante",
        EMAILPACIENTE: "Correo electrónico al paciente",
        OBSERVACOES: "Observaciones",
        ENTREGA: "Entrega",
        HIPOTESE: "Hipótesis de diagnóstico",
        INDICACAO: "Indicación clínica",
        MEDICAMENTOS: "Medicamentos",
        AUTORIZACAO: "Autorización",
        GUIAOPERADORA: "Guía del operador",
        SENHA: "Contraseña",
        AUTORIZADOEM: "Autorizado en",
        VALIDADE: "Validez",
        CONSULTAR: "Consulte",
        CANCELAR: "Cancelar",
        FECHARMENSAGEM: "Cerrar mensaje",
        DESCONTOACORDADO: "Descuento acordado",
        TOTAL: "Total",
        COMDESCONTO: "Con descuento",
        CONDICAO: "Condiciones de pago",
        EXAME: "Examen",
        EXAMEFATURADO: "Examen facturado",
        PRECO: "Precio",
        GUIASADT: "Guías SADT's",
        NUMERO: "Número",
        INCLUIRGUIASADT: "Incluya la guíaSP/SADT",
        RESPOSTAS: "Respuestas",
        NAOEXISTERESPOSTA: "No hay respuesta",
        NENHUMAINFORMACAO: "No se ha encontrado información",
        EDITAR: "Editar",
        VINCULOS: "Enlaces",
        GUIA: "Guía",
        VINCULARGUIA: "Guía para enlace",
        VINCULAR: "Enlace a",
        ANEXOS: "Anexos",
        ARRASTEARQUIVOS: "Arrastre los archivos aquí o haga clic para seleccionarlos",
        APENASPDF: "Sólo en formato PDF",
        ANEXO: "Anexo",
        REMOVERANEXO: "Eliminar los anexos",
        SALVAR: "Guardar",
        COMPROVANTES: "Recibos",
        FICHA: "Ficha",
        ATENDIMENTO: "Asistencia",
        NOVA: "Nuevo",
        TITULOLOCALORIGEM: "Lugar de origen obligatorio",
        MENSAGEMLOCALORIGEM: "Especifique un lugar de origen para continuar.",
        MEDICOOBRIGATORIO: "Solicitante obligatorio",
        CONDICAOPAGAMENTO: "Condición de pago obligatorio.",
        MENSAGEMCONDICAOPAGAMENTO: "Especifique un plazo de pago para continuar.",
        TITULOEXAMESOBRIGATORIOS: "Exámenes obligatorios",
        MENSAGEMEXAMESOBRIGATORIOS: "La guía debe tener al menos 1 examen.",
        MEDICAMENTOSOBRIGATORIOS: "Medicina obligatoria",
        MENSAGEMMEDICAMENTOSOBRIGATORIOS: "Estas examenes necesitan que informes de los <b>medicamentos</b> en uso.",
        HIPOTESEDIAGNOSTICAOBRIGATORIA: "Hipótesis Diagnóstica obligatoria",
        MENSAGEMHIPOTESEDIAGNOSTICA: "Se deve informar la <b>hipótesis diagnóstica</b> para este examen.",
        DATAMENSTRUACAO: "Fecha de la última menstruación requerida",
        MENSAGEMDATAMENSTRUACAO: "Estos exámenes necesitan que informes la <b>Fecha de la última menstruación</b>.",
        PESO: "Peso obligatorio",
        MENSAGEMPESO: "Confirma la inclusión del peso del paciente?",
        ALTURA: "Altura obligatoria",
        MENSAGEMALTURA: "Confirma la inclusión de la altura del paciente?",
        PACIENTEDESATIVADO: "No es posible crear una ficha para un paciente desactivado.",
        EXAMEJAINCLUIDO: "Examen ya incluido",
        CONFIRMAINCLUSAO: "Confirma la inclusión del examen: <b> ",
        BLOQUEIAEXAMEDUPLICADO: "Bloqueo del examen de duplicados activo",
        MENSAGEMDUPLICADO: "Para introducir este examen más de una vez, elimine el bloque de prueba duplicado en el registro de pruebas",
        BLOQUEIOGENERO: "Bloque de examen por género",
        MENSAGEMBLOQUEIOGENERO: "Este examen no puede ser elegida para este paciente.",
        INCLUSAOEXAMES: "Inclusión del examen",
        ENCONTRADO: "El examen ya se ha encontrado <b>",
        NESTAGUIA: "</b> en esta guía",
        EXAMECOMPLEMENTAR: "El examen complementario ",
        NAOPODE: " no se pudo introducir para este paciente.",
        REPETIREXAME: "Repetir el examen",
        CONFIRMAREPETIR: "Confirmar la repetición de los exámenes:<br/>",
        AMOSTRAENVIADA: "Muestra enviada para su integración: ",
        REIMPRIMIRAMOSTRAS: "Solicitud de reimpresión de las muestras: ",
        EXAMESINTEGRACAO: "Exámenes enviados para su integración",
        REGISTRARRESULTADOS: "Registro de la entrega de resultados",
        MENSAGEMREGISTRARRESULTADOS: "Exámenes:<br/>",
        IMPRESSAOSOLICITADA: "Se solicita la impresión de etiquetas para la muestra: ",
        IMPRESSAOSOLICITADAGUIA: "Se solicita la impresión de etiquetas para la guía: ",
        NOVOCODIGO: "Nuevo código de barras",
        EXCLUIRANEXO: "Eliminación de un anexo",
        CONFIRMAEXCLUSAO: "Confirma la eliminación del archivo adjunto: <b>",
        NENHUMPENDENTE: "No se ha seleccionado ningún examen pendiente.",
        MENSAGEMEMAILMEDICO: "Enviar el informe por correo electrónico al solicitante",
        EMAIL: "Correo electrónico",
        EMAILPACIENTEMENSAGEM: "Enviar el informe por correo electrónico al paciente",
        ENVIADOSUCESSO: "Mensaje enviado con éxito!",
        ALTERACODIGO: "Cambiar el código de muestra",
        NAOEXISTERESPOSTAS: "No hay respuestas",
        TITULOINSCRICAOOBRIGATORIA: "Paciente no tiene registro.",
        MENSAGEMINSCRICAOOBRIGATORIA: "El seguro de salud seleccionado exige el registro del paciente.",
        ESPECIE: "Especie: ",
        NOTIFICAREXAME: "El examen no se realiza hoy",
        DIASDISPONIVEIS: "Días en que se realiza el examen: ",
        DOMINGO: "Domingo",
        SEGUNDA: "Martes",
        TERCA: "Martes",
        QUARTA: "Miércoles",
        QUINTA: "Jueves",
        SEXTA: "Viernes",
        SABADO: "Sábado",
        INCLUIROUTRASDESPESAS: "Otros gastos",
        OBSERVACOESDOPORTAL: "Observaciones del Portal",
        PREENCHERSADT: "Rellenar la Guía del SADT",
        PREENCHERAUTORIZACAO: "Rellenar autorización",
    },
    SOROTECA:{
        AMPLIAR: "Agrandar",
        DIMINUIR: "Disminuir",
        GELADEIRA: "Refrigerador",
        CONTAINER: "Contenedor",
        CONSERVANTE: "Conservador",
        EM: "Em",
        EXAMES: "Examenes",
        NUMEROGUIA: "Número da Guía",
        CODIGOAMOSTRA: "Cód. da Muestra",
        AMOSTRA: "Muestra",
        MATERIAL: "Material",
        MULTI:"Refrigerador/Contenedor/Posición",
        SETOR: "Sector",
        PERIODOENVIO: "Periodo de Envio",
        FILTRAR: "Filtrar",
        SALVAR: "Guardar",
        REMOVER: "Eliminar",
        CANCELAR: "Cancelar",
        LIMPAR: "Limpia",
        ANALISE: "Analize",
        SOROTECAARMAZENAR: "Soroteca - Almacenar",
        SOROTECA: "Soroteca",
        ATENCAOTITULO: "Aviso",
        AMOSTRASSELECIONADAS: "Elija una posición para las muestras:",
        REMOVERAMOSTRASTITULO: "Quieres eliminar todas las muestras?",
        SIM: "Sí",
        NAO: "No",
        AMOSTRAJACADASTRADA: "Muestra ya registrada",
        AMOSTRANAOENCONTRADA: "Muestra no encontrada",
        AMOSTRANAODISPONIVEL: "Muestra no disponible",
        AMOSTRANAOPRONTACONFIRMAR: "Hay exámenes no preparados en esta muestra. Quieres incluirlo de todos modos?",
        AMOSTRANAOPRONTACONTINUAR: "Hay exámenes no preparados en esta muestra. Continuar sin inserir.",
        CONTAINERGELADEIRANAOSELECIONADO: "Es necesario seleccionar el contenedor y el refrigerador.",
        AMOSTRAERROAOSALVAR: "Ocurrió un error al guardar",
        POSICOESTITULO: "Posiciones:",
        PROXPAGINA: "Página siguiente",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGINAATUAL: "Página actual",  
        NAOEXISTEMREGISTROS: "No hay registros para mostrar.", 
        RESULTADOASSINADO: "Resultado Firmado",           
        RESULTADODIGITADO: "Resultado Mecanografiado",             
        AMOSTRACANCELADA: "Cancelado",   
        SELECIONARAOMENOSUM:"Se debe seleccionar al menos un elemento",   
        LOTEAMOTRAS: "Lote de Amostras",
        CONFIRMAINCLUSAOLOTE: "Confirmar inclusão do lote na soroteca ? As amostras atuais armazenadas serão removidas"
    },    
    GUIAINDICADORES: {
        SEPSE: "SEPSIS",
        DORTORACICA: "Dolor en el pecho",
        AVC: "AVC",
        TRAUTOMALOGIA: "Traumatología",
        RECEBERALTA: "A punto de ser dado de alta",
        GUIAPENDENTE: "Guía pendiente",
        NECESSIDADES: "Necesidades especiales",
        GUIACONFERIDA: "Guía conferido",
    },
    MODALINFOEXAME: {
        INFO: "Información",
        GUIAITEM: "Guia - Item",
        EXAME: "Examen",
        SETOR: "Sector",
        DATAENTREGA: "Fecha de entrega",
        UNIDADE: "Unidad",
        CODIGOINTEGRACAO: "Código de integración:",
        ULTIMAMODIFICACAO: "Última modificación:",
        BANCADA: "Bancada",
        CONTARECEBER: "Cuentas por cobrar",
        NAUNIDADE: "en la unidad",
        RESULTADORETIFICACAO: "Resultados antes de la rectificación",
        RESULTADOREPETICAO: "Resultados antes de la repetición",
        RESULTADONOVO: "Resultados antes de la nueva muestra",
        FECHAR: "Cerrar",
    },
    INFOAMOSTRA: {
        AMOSTRA: "Muestra",
        CODIGOBARRAS: "Código de Barras",
        MATERIAL: "Material",
        CONSERVANTE: "Conservador",
        ACONDICIONAMENTO: "Acondicionamiento",
        VOLUME: "Volumen",
        LOTE: "Lote",
        CODIGO: "Código de integración:",
        CODIGOINTEGRACAO: "Código de barras del integración:",
        CONTADOR: "Contable",
        ORIGEM: "Origen",
        GRUPOINTERFACE: "Grupo de interfaz",
        MEIOCOLETA: "Media colección",
        AMOSTRAMAE: "Muestra madre",
    },
    SOLICITARNOVAAMOSTRA: {
        RECOLETA: "Recoleta",
        NOVAAMOSTRA: "Nueva Muestra",
        AMOSTRABASE: "Muestra base:",
        CANCELARAMOSTRABASE: "Cancelar la muestra base",
        SELECIONEMOTIVO: "Seleccione el razón",
        SELECIONEMATERIAL: "Seleccione el Material",
        EXAMES: "Exámenes:",
        SELECIONAEXAMES: "Seleccione los exámenes",
        OBSERVACOES: "Observación",
        FECHAR: "Cerrar",
        MOTIVOOBRIGATORIO: "Razón obligatorio",
        MENSAGEMMOTIVOOBRIGATORIO: "Especifique una razón para continuar.",
        MATERIALOBRIGATORIO: "Material obligatorio",
        MENSAGEMMATERIALOBRIGATORIO: "Especifique um material para continuar.",                
        SUCESSO: "La muestra incluyó con éxito",
        EMAILENVIADO: "Correo electrónico enviado",
    },
    ALTERARDATASOLICITACAO: {
        ALTERAR: "Cambiar fecha de solicitud",
        DATAHORA: "Seleccione la fecha y la hora",
        CANCELAR: "Cancelar",
    },
    ALTERARDATACOLETA: {
        ALTERAR: "Cambiar fecha de colección",
    },
    ALTERARDATAULTIMAREFEICAO: {
        ALTERAR: "Elige la fecha y la hora de la última comida",
        CANCELAR: "Cancelar",
    },    
    CANCELANDOAMOSTRA: {
        CANCELARAMOSTRA: "Cancelar la muestra",
        CANCELARSELECIONADAS: "Esto cancelará las muestras seleccionadas.",
        AMOSTRAS: "Muestras",
        MOTIVOS: "Razones",
        ATENCAO: "Atención!",
        CONFIRMARCANCELAMENTO: "Confirmar la cancelación",
        CANCELAR: "Cancelar",
    },
    ALTERACAOSTATUSAMOSTRA: {
        ALTERACAOSTATUS: "Alteração de Status de Amostra",                
        MENSAGEM: "Para realizar a alteração para um status anterior é necessário definir um motivo",
        MOTIVOS: "Motivos",
        ATENCAO: "Atenção !",
        CONFIRMARALTERACAO: "Confirmar Alteração",
        CANCELAR: "Cancelar",
        MOTIVOOBRIGATORIO: "Motivo obrigatório",
        MENSAGEMMOTIVOOBRIGATORIO: "Especifique um motivo para continuar.",        
    },     
    CANCELANDOCOLETA: {
        CANCELARCOLETA: "Cancelar la recogida de muestras",
        ATENCAO: "Atención!",
        CANCELARSELECIONADAS: "Este procedimiento cancelará la recogida de la muestra seleccionada.",
        AMOSTRAS: "Muestras",
        MOTIVOS: "Razones",
        CONFIRMARCANCELAMENTO: "Confirmar la cancelación",
        CANCELAR: "Cancelar",
    },
    INCIDENCIAAMOSTRA: {
        REGISTRAR: "Registrar incidência de amostra",
        AMOSTRAS: "Muestras",
        MOTIVOS: "Razones",
        ATENCAO: "Atención!",
        CANCELAR: "Cancelar",
    },
    DESBLOQUEIOEXAME: {
        DESBLOQUEIO: "Desbloquear el examen",
        ATENCAO: "Atención!",
        DESBLOQUEIASELECIONADOS: "Este procedimiento desbloqueará los exámenes seleccionados.",
        EXAMES: "Exámenes",
        MOTIVOS: "Razones",
        CONFIRMARDESBLOQUEIO: "Confirmar el desbloqueo",
        CANCELAR: "Cancelar",
    },
    ALERTAEXAME: {
        REPORTAR: "Informe de examen",
        EXAMES: "Exámenes",
        EMAIL: "Correo electrónico",
        MOTIVOS: "Razones",
        INFO: "Información",
        CANCELAR: "Cancelar",
    },
    MENUANALISE: {
        VOLTAR: "Volver",
        ENVIAREMAILCONVENIO: "Enviar correo electrónico al Seguro de salud",
        NUMERODAGUIA: "Número de guía",
        EMITIDO: "Emitido",
        APENASGUIASCOMPLETAS: "Devolver solo guías completas",
        RESULTADOSSEMVISUALIZACAO: "Devolver solo los resultados de PDF no vistos",
        RESULTADOSSEMEMAILCONVENIO: "Devolver solo resultados sin enviar correo electrónico al Seguro de salud",
        RESULTADOSSEMEMAILPACIENTE: "Devuelva los resultados solo sin enviar un correo electrónico al paciente",
        RESULTADOSSEMEMAILMEDICO: 'Devolver solo los resultados sin enviar un correo electrónico al Solicitante',
        RESULTADOSNAOIMPRESSOS: "Devolver solo resultados no impresos",
        LOCALORIGEM: "Lugar de Origen",
        LOCALENTREGA: "Lugar de Entrega",
        LOTERESULTADOS: "Resultados por lote",
        SALVAR: "Guardar",
        ESTUDOSTITULO: "Estudios",
        ESTUDOSSEMVINCULO: "Estudios no vinculados",
        DATAEMISSAO: "Fecha de emisión",
        AMOSTRASCOMINCIDENCIA: "Muestras con Incidencia", 
        UNIDADE: "Unidad",
        GUIASNAOECONTRADAS: "No se encontraron pestañas con los filtros seleccionados",
        NAOEXIBIREXAMES: "No mostrar exámenes ordenados",
        SELECIONESETORES: "Seleccione el(los) Sector(es)",
        SELECIONELOCAL: "Seleccione el(los) lugar(es)",
        SELECIONECONVENIO: "Seleccione el(los) seguro(s) de salud",
        SELECIONEDEPARTAMENTOS: "Seleccione el(los) departamento(s)",
        SELECIONEUNIDADES: "Seleccione la(s) unidad(es)",
        ATUALIZAR: "Actualizar",
        CONVENIO: "Seguro de salud",
        SETOR: "Sector",
        EXAMESBLOQUEADOSTITULO: "Exámenes bloqueados",
        MAPATRABALHO: "Mapa de trabajo",
        EMISSAO: "Emisión",
        PROGRESSOPOR: "Progreso por:",
        BANCADA: "Bancada",
        GUIASURGENTESINDICADORES: "Guías Urgentes / con Indicadores",
        LISTARPENDENCIAS: "Lista Pendiente",
        GUIASURGENTESLOCAL: "Guías Urgentes por Ubicación",
        GUIASINDICADORES: "Guías con Indicadores",
        ACOMPANHAMENTO: "Seguimiento",
        EXAMES: "Examenes",
        EXAME: "Examen",
        ENTREGAS: "Entregas:",
        AMOSTRAS: "Muestras:",
        ESTUDOS: "Estudios:",
        TODOS: "Todos",
        DIGITACAORESULTADOS: "Escribir resultados",
        PROCURARPOR: "Buscar por ",
        CODIGOBARRAS: "Código de barras",
        GUIA: "Guía",
        PROCURARSETOR: "Buscar por sector",
        LISTARPENDENCIASDIGITACAO: "Enumerar los problemas de escritura por:",
        LOCAL: "Lugar",
        NENHUMAPENDENCIAFILTROSELECIONADO: "Sin resultados para el filtro seleccionado",
        ASSINATURARESULTADOS: "Suscripción de resultados",
        EMLISTA: "en Lista",
        PORGUIA: "por Guía",
        SOMENTEURGENTES: "Solo urgente",
        LISTARPENDENCIASASSINATURA: "Enumerar problemas de suscripción por:",
        DIGITACAORESULTADOSLOTE: "Escritura de resultados por lotes",
        DIGITACAOLOTE: "Escritura por lotes",
        EMISSAORESULTADOSLOTE: "Emisión de Resultados por Lote",
        EMITIRLOTE: "Emitir por lote",
        SOROTECA: "Soroteca",
        ARMAZENARAMOSTRAS: "Almacenar muestras",
        SOLICITACAO: "Solicitado",
        COLETA: "Recolectado",
        ENTREGA: "Entregado",
        BANCADASETOR: "Bancada y Sector",
        AMOSTRA: "Muestra",
        EXAMESBLOQUEADOS: " exámenes bloqueados",
        EXAMEBLOQUEADO: " examen bloqueado",
        AMOSTRASINCIDENCIAS: " muestras con incidencias",
        AMOSTRAINCIDENCIA: " muestra con incidencia",
        ESTUDOSVINCULO: " estudios no vinculados",
        ESTUDOVINCULO: " estudio no vinculado",
        BUSCARLOTE: "Buscar Lote",
        VALIDADOS: "Validados",
        NAOVALIDADOS: "No Validados",
    },
    MENUFINANCEIRO: {
        RATEIO: "Rateio",
        SALVAR: "Guardar",
        SELECIONEESPECIE: "Seleccione el tipo de pago",
        ESPECIE: "Tipo de pago",
        OBSERVACAO: "Observaciones",
        VENCIMENTO: "Vencimiento",
        EMISSAO: "Emisión",
        CONTACORRENTE: "Cuenta Corriente",
        BAIXAPAGAMENTOS: "Cancelación de pagos",
        FORNECEDOR: "Proveedor",
        SOMENTEPARTICULARES: "Solo privados",
        SOMARVALORESPARCELAS: "Agregar valores de cuotas",
        FILTRARDATAPROCESSAMENTO: "Filtrar por fecha de procesamiento",
        RECEBIMENTOSATRASADOS: "Recibos tardíos",
        PAGAMENTOSPROXIMOS: "Pagos Próximos",
        CONTASRECEBER: "Cuentas por cobrar",
        LOTECONTARECEBER: "Lote de cuentas por cobrar",
        CONTASPAGAR: "Cuentas por pagar",
        PERIODO: "Seleccionar periodo",
        LOTECONTAPAGAR: "Lote de cuentas por pagar",
        CONTACORRENTESCARTAO: "Cuentas Corrientes / Tarjetas",
        CATEGORIAFINANCEIRA: "Categoria financeira",
        BANDEIRAS: "Banderas",
        RELATORIOSRAPIDOS: "Informe Rápido",
        ESTATISTICAS: "Estadísticas",
        BAIXAPAGAMENTOSPERIODO: "Descargar Pagos por periodo",
        PLACEHOLDERPROCURAR: "Buscar",
        BTNLISTARTODOS: "Listar todos",
        BTNINCLUIR: "Incluir",
        BTNBAIXAR: "Descargar",
        BTNFATURAR: "Facturar",
        BTNESTATISTICAS: "Estadísticas",
        BTNLISTARRECEBIMENTOSATRASADOS: "Lista de recibos tardíos",
        BTNLISTARPARCELASFUTURAS: "Lista de plazos futuras",
        LBLBAIXAPAGAMENTOSPERIODO: "Bajo de pagos por periodo",
        ACOMPANHAMENTO: "Seguimiento",
        REPASSEMEDICO: "Repase Solicitante",
        CENTRORESULTADOS: "Centro de Resultados",
        CENTRORECEITASEDESPESAS: "Centro de Receitas e Despesas",
        DRETITULO: "Demonstrativo de Resultado do Exercício",
        EMITIR: "Emisión",
        BTNFLUXOCAIXA: "Flujo de caja",
    },
    CONCILIACAOBANCARIA: {
        ID: "Id",
        CONCILIACAO: "Conciliación bancaria",
        CONCILIAR: "Conciliar",
        DESCONCILIAR: "Desconciliar",
        VALORESPERADO: "Valor esperado",
        VALORREGISTRADO: "Valor registrado",
        VALOR: "Valor",
        DATAVENCIMENTO: "Fecha de vencimiento",
        DATATRANSACAO: "Fecha de transacción",
        NOTAFISCAL: "Factura",
        REFERENCIA: "Referencia",
        PARCELA: "Parcela",
        TRANSACAO: "Transacción",
        VALORTRANSACAO: "Valor transacción",
        TOTALSELECIONADO: "Total seleccionado",
        FILTRO: "Filtro",
        BUSCAR: "Buscar",
        CONFIRMAR: "Confirmar",
        SELECIONADO: "Seleccionado",
        ESPECIEPAGAMENTO: "Tipo de pago",
        TIPOREGISTRO: "Tipo de registro",
        NENHUMLANCAMENTORELACIONADO: "Sin lanzamiento relacionado",
        IMPORTAROFX: "Importar OFX",
        IMPORTACOES: "Importaciones",
        IMPORTADOPOR: "Importado por",
        CONTACORRENTE: "Cuenta corriente",
        SALDOANTERIOR: "Saldo anterior",
        ARQUIVO: "Archivo",
        PERIODOINICIAL: "Periodo inicial",
        PERIODOFINAL: "Periodo final",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        CANCELAR: "Cancelar",
    },
    CONTASRECEBERLISTAR:{
        PLACEHOLDERPROCURAR: "Búsqueda por nombre",
        INCLUIR: "Incluir",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        DATA: "Fecha",
        DEPARTAMENTO: "Departamento",
        CONTACORRENTE: "Cuenta corriente",
        QTDEXAMES: "Qtde. Exámenes",
        VALOR: "Valor",
        PDFSINTETICO: "PDF Sintético",
        XLSSINTETICO: "XLS Sintético",
        TITULO: "Cuentas a recibir",
        PDF: "PDF",
        XLS: "XLS",
        GLOSA: "Glosa",
    },
    CONTASRECEBERCADASTRO:{
        CONTASRECEBER: "Cuentas a recibir",
        DOCUMENTO: "Documento",
        PRESTADOR: "Proveedor",
        TOMADOR: "Prestatario",
        VALOR: "Valor",
        SITUACAO: "Situación",
        IDENTIFICACAONOTAFISCAL: "Identificación de la factura",
        NUMERONFSE: "Número NFSE",
        SERIE: "Sério",
        LOTE: "Lote",
        NUMERO: "Número",
        CODIGOVERIFICACAO: "Código de verificación",
        AUTORIZACAO: "Autorización",
        MENSAGEM: "Mensaje",
        CONTACORRENTE: "Cuenta corriente",
        DATA: "Fecha",
        PLACEHOLDERDATA: "Seleccione una fecha",
        NOTAFISCAL: "Factura",
        FUNCIONARIO: "Empleado",
        LOCALORIGEM: "Lugar de origen",
        UNIDADE: "Unidad",
        OBSERVACOES: "Observaciones",
        FECHARFATURA: "¿Cerrar factura?",
        FATURAFECHADA: "Factura cerrada",
        TISS: "TISS",
        TISSLOTEGUIAS: "Billetes de lote TISS",
        GERARDOCUMENTO: "Generar documento",
        PDF: "PDF",
        PDFSINTETICO: "PDF Sintético",
        XLS: "XLS",
        EMITIRNOTAFISCAL: "Emitir factura",
        CONSULTARNOTAFISCAL: "consultar la factura de impuestos",
        PDFNOTAFISCAL: "PDF factura de venta",
        CANCELARNOTAFISCAL: "Cancelar factura de venta",
        CANCELARFATURA: "Cancelar la factura",
        PARCELACANCELADA: "Paquete cancelado",
        PARCELARVALOR: "Valor de la cuota",
        DESCONTO: "Descuento",
        DESCONTOTOTAL: "Descuento Total: R${0}",
        PARCELAS: "Parcelas",
        MENSAGEMVALORNAOBATE: "La suma total de los valores ({0}) no coincide con el valor total de los exámenes ({1}).",
        VENCIMENTO: "Fecha de vencimiento",
        PLACEHOLDERSELMOTIVO: "Seleccione el motivo",
        ESPECIE: "Tipo de pago",
        DADOS: "Datos",
        AGENCIA: "Agencia",
        PLACEHOLDERAUTORIZACAO: "Autorización",
        BANCO: "Banco",
        BANDEIRA: "Bandera",
        RECEBIMENTO: "Recepción",
        BOLETO: "Billete",
        CONTROLEINTERNO: "Control interno",
        MENSAGEMREEMITIRBOLETO: "Quieres reemitir el boleto?",
        REEMITIRBOLETO: "Volver a generar Boleto",
        GERARBOLETO: "Generar Boleto",
        MENSAGEMCANCELARBOLETO: "Quiere cancelar o boleto actual? ",
        CANCELARBOLETO: "Cancelar Boleto",
        VISUALIZARBOLETO: "Visualizar  Boleto",
        ANALISAR: "Analizar",
        RECEBIDOEM: "Recibido el",
        CANCELAR: "Cancelar",
        IMPRIMIRRECIBO: "Imprimir Recibo",
        CANCELADO: "Cancelado",
        PAGAMENTOCANCELADO: "Pago Cancelado",
        LIBERADO: "Liberado",
        ANALISE: "Análisis",
        LIBERAR: "Liberar",
        REJEITAR: "Rechazo",
        REJEITADO: "Rechazado",
        RECEBIDO: "Recibido",
        PENDENTE: "Pendiente",
        ERROGERARBOLETO: "No es posible generar boletos",
        LISTAEXAMES: "Lista de exámenes",
        GUIA: "Guía",
        ITEM: "Item",
        EXAME: "Examen",
        REMOVIDO: "Eliminado",
        PRECO: "Precio",
        GLOSARGUIA: "Guía de glosa",
        INCLUIRGUIA: "Guía de inclusión",
        GLOSAS: "Glosses",
        TOTAL: "Total",
        SALVAR: "Guardar",
        CONVENIO: "Convenio",
        CONSUMIVEIS: "Consumiveis",
        PEDIDO: "Pedido",
        CONSUMIVEL: "Consumivel",
        QUANTIDADE: "Quantidade",
        PRECOUNITARIO: "Valor unidad",        
        GLOSALOTERGUIA: "Lote de glosas",
        LOTEDEGLOSA: "Lote de glosas generadas!",
        ARQUIVOIMPORTADO: "Fichero ya importado: ",
        IMPOSTOS: "Impuestos",
        ID: "Codigo",
        IMPOSTO: "Impuesto",
        ALIQUOTA: "Aliquota",
        CATEGORIAFINANCEIRA: "Categoria financeira"
    },
    LOTESCONTARECEBERLISTAR:{
        PLACEHOLDERPROCURAR: "Búsqueda por nombre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        DESCRICAO: "Descripción",
        DATAINICIAL: "Fecha inicial",
        DATAFINAL: "Fecha final",
        QTDCONTAS: "Nr. Cuentas",
        ID: "Id",
        DATA: "Fecha",
        CONTACORRENTE: "Cuenta Corriente",
        VALOR: "Valor",
        TITULO: "lote de Cuentas por Cobrar"
    },
    LOTESCONTARECEBERCADASTRO:{
        TITULO: "Cuentas por Cobrar",
        DESCRICAO: "Descripción",
        CONVENIO: "Convenio",
        DATAINICIAL: "Fecha Inicial",
        PLACEHOLDERSELECIONEUMADATA: "Seleccione una Fecha",
        DATAFINAL: "Fecha Final",
        CONTACORRENTE: "Cuenta Corriente",
        ESPECIE: "Especie",
        OBSERVACAO: "Observación",
        LOCALIZARCONTAS: "Seguir las Cuentas",
        CONTAS: "Cuentas",
        DOCUMENTO: "Documento",
        NOTAFISCAL: "Factura de Compra",
        EMISSAO: "Emisión",
        VENCIMENTO: "Vencimiento",
        VALOR: "Valor",
        SITUACAO: "Situación",
        PENDENTE: "Pendiente",
        PAGO: "Pagado",
        CANCELADO: "Cancelado",
        BAIXAR: "Descarga",
        CANCELARLOTE: "Cancelar Lote",
        ARQUIVOS: "Archivos",
        DESCONTO: "Descuento",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        ENVIADO: "Email enviado?",
        ENVIAREMAIL: "Envia email",
        FECHARFATURA: "¿Cerrar factura?",
        EMITIRDOCUMENTOS: "Expedición de documentos",
        CONSULTARNOTAFISCAL: "Consultar la factura de impuestos",
        EMITIRLOTE: "Lote de billetes enviados para su emisión, esto puede tardar unos minutos.",
        CONSULTARLOTE: "Consulta solicitada, puede tardar unos minutos.",
        FECHARFATURAMSG: "¿De verdad quieres cerrar la factura? No podrá realizar cambios después de esta acción.",
        BOLETO: "Boleto",
        MENSAGEMNOTIFICAOENVIOEMAIL: "Los documentos ",
        MENSAGEMNOTIFICACAO: "no están listos para ser enviados. Por favor, evalúe la condición de pago.",
        BANDEIRA: "Bandera"
    },
    CONTASPAGARLISTAR: {
        PLACEHOLDERPROCURARPORNOME: "Búsqueda por nombre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        DATA: "Fecha",
        DESCRICAO: "Descripción",
        PEDIDO: "Orden",
        CATEGORIAFINANCEIRA: "Categoria financeira",
        TITULO: "Cuentas por pagar",
        FORNECEDOR: "Proveedor",
        VENCIMENTO: "Vencimiento",
        VALOR: "Valor",
        MOEDA: "S/.",
    },
    CONTASPAGARCADASTRO: {
        COMPETENCIA: "Competencia",
        DATA: "Fecha",
        DESCRICAO: "Descripción",
        PEDIDO: "Orden",
        CATEGORIAFINANCEIRA: "Categoria financeira",
        PLACEHOLDERCATEGORIAFINANCEIRA: "Seleccione la categoria financeira",
        PLACEHOLDERDATA: "Seleccione una fecha",
        NOTAFISCAL: "Factura",
        FORNECEDOR: "Proveedor",
        PLACEHOLDERFORNECEDOR: "Seleccione el proveedor",
        CONTACORRENTE: "Cuenta corriente",
        PLACEHOLDERCONTACORRENTE: "Seleccionar cuenta corriente",
        UNIDADE: "Unidad",
        FUNCIONARIO: "Empleado",
        OBSERVACAO: "Observaciones",
        PARCELAS: "Parcelas",
        VENCIMENTO: "Vencimento",
        VALORPARCIAL: "Cantidad parcial",
        JUROS: "Interés",
        MULTA: "Penalización",
        TOTAL: "Total",
        SITUACAO: "Situación",
        DEFINIRCOMORECEBIDO: "Haga clic para definir como recibido",
        CLIQUEPARACANCELAR: "Pulse para cancelar",
        RECEBIDO: "Recibido",
        CANCELADO: "Cancelado",
        MOTIVOCANCELAMENTO: "Motivo de la cancelación",
        PAGOEM: "Pagado en",
        ESPECIE: "Especie",
        PLACEHOLDERESPECIE: "Seleccionar especies",
        CARTAO: "Tarjeta",
        CHEQUENUMERO: "Número de cheque",
        ADICIONARPARCELA: "Añadir pago parcial",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        PAGO: "Pagado",
        CAMPOESPECIEOBRIGATORIO: "¡Campo 'Especie' obligatorio!",
        ATENCAO: "Atención!",
        ID: "Id",
        CENTRORESULTADOS: "Centro de resultados",
        VALOR: "Valor",
        PORCENTAGEM: "Porcentagem",
        ADICIONARCENTRORESULTADOS: "Incluir",
        TOTALCENTROECONTASINVALIDO: "O valor total do centro de resultados e o valor total das parcelas não correspondem"
    },
    LOTECONTASPAGARLISTAR: {
        PLACEHOLDERPROCURARPORNOME: "Búsqueda por nombre",
        PLACEHOLDERDATA: "Seleccione una fecha",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        DESCRICAO: "Descripción",
        CONTACORRENTE: "Cuenta corriente",
        DATAINICIAL: "Fecha Inicial",
        DATAFINAL: "Fecha Final",
        TITULO: "Lotes de cuentas por pagar",
        TIPOBAIXA: "Tipo de Jubilación",
        QTDCONTAS: "Nr. Cuentas",
        VALOR: "Valor",
        PDF: "PDF"
    },
    LOTECONTASPAGARCADASTRO: {
        CENTRORESULTADO: "Centro de Resultados",
        REGRARATEIO: "Regras de Rateio",
        DESCRICAO: "Descripción",
        CONTACORRENTE: "Cuenta corriente",
        PLACEHOLDERDATA: "Seleccione una fecha",
        DATAINICIAL: "Fecha Inicial",
        DATAFINAL: "Fecha Final",
        TIPOBAIXA: "Tipo de Jubilación",
        OBSERVACAO: "Observación",
        LOCALIZARCONTAS: "Seguir las Cuentas",
        CONTAS: "Cuentas",
        DOCUMENTO: "Documento",
        FORNECEDOR: "Proveedor",
        NOTAFISCAL: "Factura",
        EMISSAO: "Emitido por",
        VENCIMENTO: "Fecha de vencimiento",
        VALOR: "Valor",
        SITUACAO: "Situación",
        PENDENTE: "PENDIENTE",
        PAGO: "PAGADO",
        CANCELADO: "CANCELADO",
        BAIXAR: "Descargar",
        BANDEIRA: "Bandera",
        UNIDADE: "Unidad",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        CAMPOSOBRIGATORIOS: "Recorrência {0} - Verifica Campos Obrigatórios!",
        CAMPOVALORTOTAL: "O valor total deve ser maior que zero",
        CAMPOCONTACORRENTE: "O campo conta corrente é obrigatório",
        CAMPOFORNECEDOR: "O campo fornecedor é obrigatório",
        CAMPOCATEGORIAFINANCEIRA: "O campo categoria financeira é obrigatório",  
        CAMPOCENTRORESULTADO: "Necessário escolher um Centro de Resultado ou uma Regra de Rateio",      
        CONFIRMASALVAR: "Caso tenha revisado todas as recorrências, você tem certeza que deseja salvar?",
        CATEGORIAFINANCEIRA: "Categoria financeira",
        DATABAIXA: "Fecha de alta",
    },
    CONTASCORRENTESLISTAR: {
        PLACEHOLDERPROCURAR: "Búsqueda",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        BANCO: "Banco",
        AGENCIA: "Agencia",
        NUMERO: "Número",
        TITULO: "Cuentas corrientes"
    },
    CONTASCORRENTESCADASTRO: {
        CODIGO: "Código",
        NOME: "Nombre",
        BANCO: "Banco",
        AGENCIA: "Agencia",
        NUMERO: "Numero",
        CODIGOCEDENTE: "Código Cedente",
        CNPJ: "CNPJ",
        NOSSONUMERO: "Nuestro Número",
        CARTEIRA: "Cartera",
        SERVICONOTAFISCAL: "Servicio de facturación",
        EXTRATO: "Extracto",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
    },
    CATEGORIAFINANCEIRALISTAR: {
        PLACEHOLDERPROCURAR: "Búsqueda por nombre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        ID: "Id",
        TITULO: "Categorias Financeiras"
    },
    CATEGORIAFINANCEIRACADASTRO: {
        NOME: "Nombre",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
    },
    BANDEIRALISTAR: {
        PLACEHOLDERPROCURAR: "Búsqueda por nombre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        ID: "Id",
        TITULO: "Bandera"
    },
    BANDEIRACADASTRO: {
        NOME: "Nombre",
        TAXACREDITO: "Tasa de Crédito",
        TAXADEBITO: "Tasa de Débito",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
    },
    FECHAMENTOCAIXA: {
        GUIA: "Guia",
        DATAEMISSAO: "Fecha de emisión",
        LOCALORIGEM: "Lugar de Origen",
        UNIDADE: "Unidad",
        FUNCIONARIO: "Empleado",
        VALOR: "Valor (S/.)",
        DESCONTO: "Descuento  (S/.)",
        DEBITO: "Débito (S/.)",
        RECEBER: "Recibir",
        GUIASFATURADAS: "Guías Facturadas",
        QUANTIDADE: "Cantidad",
        GUIASNAOFATURADAS: "Guías no Facturadas",
        TITULO: "Pagos en caja"
    },
    RELACAODEBITOS: {
        SOMENTEPARCELASVENCIDAS: "Sólo cuontas vencidas",
        CODIGO: "Código",
        DATAVENCIMENTO: "Fecha de caducidad",
        VALOR: "Valor (S/.)",
        TOTAL: "Total (S/.)",
        TOTALPACIENTE: "Total del Paciente",
        TITULO: "Lista de deudas"
    },
    ESTATISTICAS: {
        CONVENIOS: "Acuerdos",
        PRINCIPAIS: "Principal",
        GERAL: "General",
        SETORES: "Sectores",
        FATURAMENTO: "Facturación",
        VALOR: "Valor (S/.)",
        ATENDIMENTOS: "Asistencia",
        GUIAS: "Guias",
        PACIENTESNOVOS: "Pacientes",
        EXAMES: "Exámenes",
        FLUXOCAIXA: "Flujo de Caja",
        RECEBIMENTOS: "Recibos",
        PAGAMENTOS: "Pagos",
        LUCRO: "Beneficio",
        TITULO: "Estadísticas"
    }, 
    FATURAR: {
        FILTRO: "Filtro",
        UNIDADE: "Unidad",
        LOCALORIGEM: "Local de Origen",
        TIPOCONVENIO: "Tipo de Arreglo",
        SETOR: "Setor",
        DEPARTAMENTO: "Departamento",
        EMISSAO: "Emitido por",
        SOLICITACAO: "Solcitado",
        ALTA: "Alta",
        DIGITACAO: "Redactado",
        IMPRESSAO: "Impreso",
        COLETA: "Recolectado",
        ASSINATURA: "Firmado",
        INCLUIRCONVENIOSPARTICULARES: "Incluir acuerdos privados",
        GUIACONFERIDA: "Guía revisada",
        SELECIONADOS: "Seleccionado",
        GUIA: "Guia",
        REFERENCIA: "Referencia",
        INCLUIR: "Incluir",
        AGENDAMENTOS: "Horarios",
        ORDENARPOR: "Ordenar Por",
        DATAEMISSAO: "Fecha de emisión",
        DATASOLICITACAO: "Fecha de la solicitud",
        DATANASCIMENTO: "Fecha de nacimiento",
        NUMEROCONSELHO: "Número del Consejo",
        CNSEMVEZDEINSCRICAO: "CNS en lugar de Registro",
        SOMENTECOMMATERIALENTREGUE: "Sólo con material entregado",
        SOMENTECOMRESULTADOASSINADO: "Sólo con resultado firmado",
        AGRUPARELATROPIOPORSETOR: "Informe de grupo por sector",
        SOMENTEJAFATURADOS: "Sólo se ha facturado ya",
        SOMENTENAOFATURADOS: "Sólo no facturados (apenas PDF)",
        ESTUDOCLINICO: "Estudio clínico",
        GUIASPENDENTES: "No incluya guía pendientes.",
        CRIARDOCUMENTO: "Crear documento",
        GERARPDF: "Generar PDF",
        GERARXLS: "Generar XLS",
        CRIARAGENDAMENTO: "Crear Horario",
        VOLTAR: "Volver",
        TITULO: "Facturar",
        VALIDARTISS: "Validar el TISS",
        CONVOBRIGATORIO: "Debe seleccionar un Acuerdo o Tipo de Acuerdo",
        CARREGARARQUIVO: "Archivo de devoluciones TISS",
        RETORNOTISS: "Devoluciones TISS",
        CONVENIOOBRIGATORIO: "Es necesario informar un acuerdo",
    },
    MANUTENCAOLISTAR: {
        PESSOAS: "Personas",
        PACIENTES: "Pacientes",
        LISTARTODOS: "Lista de todos",
        INCLUIR: "Incluir",
        ESPECIE: "Especies",
        RACA: "Razas",
        MEDICOS: "Solicitantes",
        FUNCIONARIO: "Empleados",
        NIVELACESSO: "Niveles de acceso",
        EMPRESA: "Compañías",
        TIPOCONVENIO: "Tipos de seguro de salud",
        FORNECEDOR: "Proveedores",
        EXAMES: "Exámenes",
        MATERIAIS: "Materiales",
        CONSERVANTES: "Conservantes",
        ABREVIATURAS: "Abreviaciones",
        GRUPOEXAME: "Grupos de examen",
        TIPOCODIGO: "Tipo de código de examen",
        SETORES: "Sectores",
        DEPARTAMENTOS: "Departamentos",
        TABELASPRECO: "Listas de precios",
        VALORES: "Valores CBHPM",
        MOTIVOS: "Razones",
        LIBERACAOAUTO: "Liberación automática",
        CONFIGURAR: "Configurar",
        OUTROS: "Otros",
        UNIDADES: "Unidades",
        LOCAIS: "Lugares",
        TIPOSLOCAIS: "Tipos de lugares",
        TIPOAGENDAMENTO: "Tipos de programación",
        REGRASAGENDAMENTO: "Normas de programación",
        PESQUISAS: "Investigaciones",
        FERIADO: "Feriados",
        CONDPAGAMENTO: "Condiciones de pago",
        ESPECIEPAGAMENTO: "Tipo de pago",
        SOROTECA: "Soroteca - Congelador",
        CONTAINERS: "Soroteca - Contenedor",
        CONFIGTISS: "Configuración de TISS",
        CONFIGAGENDAMENTO: "Configurações de agendamentos",
        SERVICOPERIODO: "Servicios realizados periódicamente",
        ALTERAR: "Cambiar",
        PROCURAR: "Buscar en",
        LAUDODESCRITIVO: "Modelo de Reportes Descriptivos",
        REDUCAOACRESCIMO: "Reducción/Adición",
        REPASSEMEDICO: "Repase Solicitante",
        EXAMEEXTRACAO: "Examene de Extracción",
        AMOSTRASSTATUS: "Estado de Amostra",
        COLETADOR: "Coletador"
    },
    PACIENTESLISTA: {
        PROCURAR: "Buscar enmbre",
        INCLUIR: "Incluir",
        UNIRCADASTRO: "Unificar los catastros",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        NOME: "Nombre",
        SEXO: "Sexo",
        NASCIMENTO: "Nacimiento",
        EMAIL: "Correo electrónico",
        RESPONSAVEL: "Responsable",
        DESATIVADO: "Desactivado",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        ESPECIE: "Especie",
        RACA: "Raza",
    },
    PACIENTECADASTRO: {
        MEDICOPADRAO: "Médico por defecto",        
        MSGERROCPF: "DNI no válido",
        PENDENCIA: "No hay asuntos financieros pendientes", 
        NOME: "Nombre",
        NOMESOCIAL: "Nombre Social",
        ANIMAL: "Animales",
        FONE: "Teléfono",
        CELULAR: "Teléfono móvil",
        ENVIAMSG: "Envío de mensajes SMS",
        SEXO: "Sexo",
        SELECIONESEXO: "Selecciona tu sexo",
        FEM: "Mujer",
        MASC: "Hombre",
        INDT: "No especificado",
        NASCIMENTO: "Nacimiento",
        IDADE: "Edad",
        CALCIDADE: "Calcular la edad en",
        ANOS: "Años",
        MES: "Meses",
        DIA: "Días",
        PESO: "Peso (kg)",
        ALTURA: "Estatura (cm)",
        EMAIL: "Correo electrónico",
        RG: "RG",
        CPF: "DNI",
        CARTAOSAUDE: "Tarjeta sanitaria nacional",
        PLANO: "Plan",
        TITULAR: "Titular",
        PASSAPORTE: "Pasaporte",
        INSCRICAO: "Inscripción",
        DV: "D.V.",
        VALIDADE: "Validez",
        CONVPADRAO: "Seguro de salud estándar",
        RACA: "Raza",
        ESPECIE: "Especie",
        NOMEMAE: "Nombre de la madre",
        RESPONSAVEL: "Responsable",
        CADASTRORES: "Registro de Responsables",
        OBSERVACOES: "Observaciones",
        USUARIO: "Usuario",
        CAPTURAR: "Haga clic para capturar a través de la cámara web",
        CAMERA: "Cámara",
        DESATIVADO: "Desativado",
        CRIARUSUARIO: "Crear usuario web",
        CANCELARUSUARIO: "Cancelar usuario de la web",
        GUIAS: "Guías",
        LISTAR: "Lista",
        DOC: "Documentos",
        NENHUMDOC: "No hay documentos",
        PUBRESULTADOS: "Publicar los resultados",
        CHAVE: "Clave",
        INDICACAO: "Indicación",
        CODINTEG: "Códigos de integración",
        INTEG: "Integrador",
        TIPO: "Tipo",
        CODIGO: "Código",
        ADDCODIGO: "Añadir código",
        GUIAOPERADORA: "Recogida de la guía en el operador",
        GUIAOP: "Guía del operador",
        BUSCA: "Buscar en",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        CONVOBRIGATORIO: "Seguro de salud estándar obligatorio.",
        NUMGUIA: "El número de la guía en el operador es obligatorio.",
        NASCIMENTOINVALIDO: "Valor no válido para la fecha de nacimiento.",
        IDADEINVALIDA: "Valor inválido para la edad!",
        EMAILMSG: "El correo electrónico ",
        EMUSO: " ya está siendo utilizado por el ",
        RACAOBGR: "Raza obligatoria.",
        ESPECIEOBGR: "Especies obligatorias.",
        ESPECIEINVALIDA: "Especie inválida para esta raza.",
        RESPOBRG: "Responsable obligatorio.",
        CPFOBRG: "DNI o pasaporte obligatorio.",
        ENDOBRG: "Dirección obligatoria.",
        EMAILOBRG: "Correo electrónico obligatorio.",
        CELOBRG: "Teléfono móvil obligatorio.",
        FALHAUSUARIO: "No es posible crear un usuario web sin correo electrónico.",
        CPFUSO: "Este DNI ya está en uso.",
        ERROSALVARPACIENTE: "Error al guardar el paciente, comprobando el DNI: ",
        ERROPACIENTE: "Error al guardar el paciente: ",
        ERROHIST: "Error al cargar el historial: ",
        AGENDAMENTOS: "Horarios",
        RESPONSAVELOBRG: "Responsable obligatorio.",
        INSCRICAOPADRAO: "El registro no cumple las normas del pacto",
        INSCRICAOOBRIGATORIA: "Registro obligatorio",
        PRONTURARIO: "Prontuario",
        ABRIRPRONTURARIO: "Abra",
    },
    MODALSMS: {
        ENVIARMENSAGEM: "Enviar un mensaje SMS",
        NUMERO: "Número",
        MENSAGEM: "Mensaje",
    },
    ESPECIESLISTA: {
        PROCURAR: "Buscar en",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        MANUTENCAO: "Mantenimiento",
        ESPECIE: "Especies",
    },
    ESPECIECADASTRO: {
        SALVANDOESPECIE: "A espécie está sendo salva, este processo pode levar alguns segundos.",
        NOME: "Nombre",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        ESPECIE: "Especie",
    },
    RACALISTA: {
        PROCURAR: "Buscar en",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        EDITAR: "Editar",
        RACAS: "Razas",
        MANUTENCAO: "Mantenimiento",
    },
    RACACADASTRO: {
        SALVANDORACA: "La carrera se está guardando, este proceso puede tardar unos segundos.",
        NOME: "Nombre",
        ESPECIE: "Especies",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
    },
    MEDICOS: {
        PROCURARNOME: "Buscar enmbre",
        INCLUIR: "Incluir",
        UNIFICARCADASTROS: "Unificar los catastros",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        CONSELHO: "Consejo",
        ID: "Id",
        CBO: "CBO-S",
        EMAIL: "Correo electrónico",
        FONE: "Teléfono",
        DESABILITADO: "Desactivado",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
    },
    MEDICOCADASTRO: {
        NOME: "Nombre",
        FONE: "Teléfono",
        CEL: "Teléfono móvil",
        DATA: "Fecha de nacimiento",
        IDADE: "Edad",
        CPF: "DNI",
        CONSELHO: "Consejo",
        UF: "UF",
        NUMEROCONSELHO: "Número en el Consejo",
        CODOPERADORA: "Código del operador",
        CNES: "CNES",
        CBOS: "CBO-S",
        CONVPADRAO: "Seguro de salud estándar",
        EMAIL: "Correo electrónico",
        OBSERVACAO: "Observaciones",
        FOTO: "Foto",
        SELECT: "Select Device",
        CAPTURAR: "Haga clic para capturar a través de la cámara web",
        ESCOLHER: "Elija el archivo...",
        CRIARUSUARIO: "Crear usuario web",
        CANCELAUSUARIO: "Cancelar usuario de la web",
        GUIAS: "Guías",
        LISTAR: "Lista",
        PUBLICAR: "Publicar los resultados",
        CODIGOSINTEGRACAO: "Códigos de integración",
        TIPO: "Tipo",
        CODIGO: "Código",
        INTEGRADOR: "Integrador",
        ADDCODIGO: "Acrescentar código",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        CONVOBR: "Seguro de salud estándar obligatorio.",
        PREENCHEREMAIL: "Para crear un usuario web es necesario rellenar el correo electrónico",
        ERROSALVARMEDICO: "Error en el rescate del solicitante: ",
        OEMAIL: "El correo electrónico ",
        EMUSO: "ya está siendo utilizado por el ",
    },
    FUNCIONARIOLISTA: {
        PROCURARNOME: "Procurar por nombre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        NOME: "Nombre",
        NIVELACESSO: "Nivel de acceso",
        EMAIL: "Correo electrónico",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        FUNCIONARIO: "Empleados",
    },
    FUNCIONARIOCADASTRO: {
        UNIDADE: "Unidade",
        USUARIO: "Usuario",
        ALTERAR: "Cambiar",
        SENHA: "Contraseña",
        CONFIRMARSENHA: "Confirmación de la contraseña",
        CPF: "DNI",
        CONSELHO: "Consejo",
        UF: "UF",
        NUMCONSELHO: "Número en el Consejo",
        FONE: "Teléfono",
        CELULAR: "Teléfono móvil",
        DATA: "Fecha de nacimiento",
        IDADE: "Edad",
        NIVEL: "Nivel",
        EMAIL: "Correo electrónico",
        IDENTIFICADOR: "Identificación",
        ASSINATURA: "Texto de la firma",
        FOTO: "Foto",
        CAPTURAR: "Haga clic para capturar a través de la cámara web",
        ARQUIVO: "Elija el archivo...",
        DESATIVADO: "Desactivado",
        ASS: "Assinatura",
        ESCOLHERARQ: "Elija el archivo",
        MUDARSENHA: "Restablecimiento de la contraseña",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        FUNCIONARIO: "Empleados",
        SENHASIGUAIS: "Las contraseñas deben ser las mismas",
        USUARIOLOGADO: "No es posible cambiar el usuario conectado actualmente.",
        ALTERARUSUARIO: "Estás seguro de que quieres cambiar el login de este empleado?",
        CBOS: "CBOS",
        CODIGOOPERADORA: "Código del operador",
    },
    NIVELDEACESSO: {
        PROCURAR: "Buscar en",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        MANUTENCAO: "Mantenimiento",
        NIVEL: "Niveles de acceso",
    },
    NIVELDEACESSOCADASTRO: {
        SALVANDONIVEL: "El nivel se está guardando, este proceso puede tardar unos segundos.",
        NOME: "Nombre",
        ACESSALOCAIS: "Tipos de localizaciones de sólo acceso",
        ID: "Id",
        SELECIONALOCAL: "Seleccione el tipo de local",
        ADICIONALOCAL: "Añadir tipo de localización",
        ADM: "Administrador",
        RADIOLOGISTA: "Radiologista",
        ACESSOINTERNET: "Permite el acceso a través de Internet",
        REGRAS: "Reglas",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        NIVEL: "Nivel de acceso",
    },
    CONVENIOS: {
        PROCURAR: "Buscar enmbre",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        NOME: "Nombre",
        CODIGO: "Código",
        TIPOCONV: "Tipo seguro de salud",
        TABPRECO: "Tabla de precios",
        PARTICULAR: "Particulares",
        REEMBOLSO: "Reembolso",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
    },
    CONVENIOCADASTRO: {
        RESPONSAVELDEVENDAS: "Responsable de ventas",
        NOME: "Nombre",
        CONTATO: "Contacto",
        FONE: "Teléfono",
        CNPJ: "CNPJ",
        INSCRICAO: "Registro estatal",
        INSCRICAOMUN: "Inscripción municipal",
        TABPRECO: "Tabla de precios",
        TABPRECOINFO: "Tabla de precios dos examenes",
        DESCONTOPORTE: "Descuento en los gastos de envío",
        DESCONTOMAX: "Descuento máximo",
        IRRF: "IRRF",
        DESCONTO: "Descuento",
        ANS: "Registro ANS",
        CODLAB: "Código del laboratorio",
        TIPOCONV: "Tipo de Seguro de Salud",
        TISS: "Tabla TISS",
        EMAIL: "Correo electrónico",
        MODELO: "Modelo de resultado",
        MODELOCOMPROVANTE: "Modelo de recibo",
        REPRESENTANTE: "Representante",
        TABELACOPART: "Tabla de copagos",
        TABELACOPARTINFO: "Tabla de precios de copagos",
        TABELACONSU: "Tabla de consumiveis",
        TABELACONSUINFO: "Tabla de precios de consumiveis",
        TABELAURGENTE: "Tabla de urgencia",
        TABELAURGENTEINFO: "Tabla de precios para examenes de urgência",
        INFOTABELAPRECO:"Abrir la información del tabla de precio",
        RAZAOSOCIAL: "Nombre de la empresa",
        RESPLEGAL: "Responsable legal",
        RESPTEC: "Responsable técnico",
        CPFRESP: "DNI del responsable técnico",
        REGPROF: "Registro profesional",
        TELRESP: "Teléfono del responsable técnico",
        CONTATORESULTADO: "Contacto para los resultados",
        TELEFONECONTATO: "Teléfono de contacto para el resultado",
        EMAILCONTATO: "Contacto por correo electrónico para resultados",
        PRAZOPAGAMENTO: "Plazo de pago en días",
        SISTEMAUTILIZADO: "Sistema utilizado",
        RESPFINANCEIRO: "Responsable financiero",
        TEMPODESLOCAMENTO: "Tiempo de viaje",
        LIMITEAMOSTRA: "Límite de recepción de la muestra sin alerta",
        NUMEROSADT: "Número de exámenes SADT",
        UNIDADE: "Unidad estándar",
        UNIDADESDECADASTRO: "Unidades de registro",
        OBSERVACOES: "Observaciones",
        ACESSORESULTADO: "No tienen acceso a los resultados",
        HABILITASADT: "Activar las guías SADT",
        PODEINCLUIR: "Puede incluir una guía de Internet",
        NAOAGRUPAR: "No se deben agrupar las pruebas de las muestras existentes",
        EXIBIROBSERVACAO: "Visualizar la observación en la ficha",
        VERAMOSTRA: "No ver muestras de integración",
        GERAETIQUETA: "Genera la etiqueta de precolección",
        ENVIAANEXO: "Envía el archivo adjunto de la guía en la integración",
        DESATIVADO: "Desactivado",
        ESCOLHEMEDICO: "Permite elegir solicitantes en el portal",
        GUIALIBERADA: "Ver sólo los resultados de las guías completadas",
        USUARIOS: "Usuarios",
        CANCELARUSUARIOS: "Cancelar el usuario Web Principal",
        CRIARUSUARIO: "Crear usuario web",
        GERENCIAUSUARIO: "Gestionar usuarios secundarios",
        GUIAS: "Guías",
        LISTARTABELA: "Enumerar la tabla de precios de los exámenes de este acuerdo",
        LISTARGUIAS: "Lista de Guías",
        PUBLICARRESULTADO: "Publicar los resultados",
        HABILITARESTOQUE: "Habilitar existencias",
        SOMENTEELETIVOSTISS: "Somente eletivos Tiss",
        CODINTEGRACAO: "Códigos de integración",
        CHAVEINTERNET: "Clave de integración",
        TIPO: "Tipo",
        CODIGO: "Código",
        ADDCODIGO: "Añadir código",
        TISSCADASTRO: "TISS",
        VERSAO: "Versión",
        URLWEB: "URL webservices",
        LOGIN: "Login",
        SENHA: "Contraseña",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        ERROSALVAR: "Error al guardar el seguro de salud: ",
        OEMAIL: "El correo electrónico ",
        EMUSO: " ya está siendo utilizado por el ",
        ERROUSUARIO: "Error al eliminar el usuario: ",
        ETIQUETAPRECOLETA: "Etiqueta de precolección",
        QUANTIDADEETIQUETA: "Cuántas etiquetas hay que generar?",
        QUANTIDADE: "Cantidad",
        ENDERECORECOLHIMENTO: "Dirección de entrega",
        HORARIOROTA: "Tiempo de ruta",
        RESPONSAVELTRIAGEM: "Responsable del triaje",
        INSCRICAOOBRIGATORIA: "Hacer que el campo de registro del paciente sea obligatorio",
        EXIBEFATURA: "Muestra las facturas",
        CONDICAO: "Condiciones de pago",
        BLOQUEIALAUDO: "Bloqueo de informes expirados",
        CRIARRECEBERPAGAMENTO: "Criar receber pagamento",
        PADRAOCARTEIRA: "Cartera estándar",
        COLETAEXTERNAPORTAL: "Coleta Externa no Portal",
        MODELETQPORTAL: "Ejemplo de etiqueta en el portal",
        CATEGORIAFINANCEIRA: "Categoria financeira",
        EQUIPESADT: "Equipo SADT TISS",
        IGNORACH: "TISS sin valor CH",
        ERRORCATEGORIAFINANCEIRA: "¡Categoría financiera obligatoria!",
        CAMPOOBRIGATORIO: "El campo de categoría financiera es obligatorio",
    },
    TIPOCONVENIO: {
        PROCURAR: "Buscar en",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        TIPOCONV: "Tipos de seguro de salud",
    },
    TIPOCONVENIOCADASTRO: {
        SALVANDOTIPO: "El tipo de acuerdo se está guardando, este proceso puede tardar unos minutos.",
        NOME: "Nombre",
        PRAZOPENDENTE: "Plazo pendiente",
        PRAZOANALISE: "Plazo Análisis",
        TIPOCODIGO: "Tipo de Código",
        SELECIONETIPO: "Seleccione el tipo",
        REEMBOLSO: "Reembolso",
        PARTICULAR: "Privado",
        FATURAABERTA: "Factura abierta",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        TIPOCONV: "Tipo de seguro de salud",
        CONVPADRAO: "Convênio Padrão"
    },
    FORNECEDORES: {
        PROCURAR: "Buscar enmbre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        NOME: "Nombre",
        CONTATO: "Contacto",
        TELEFONE: "Teléfono",
        MANUTENCAO: "Mantenimiento",
        FORNECEDOR: "Proveedores",
    },
    FORNECEDORCADASTRO: {
        CONTATO: "Contato",
        TELEFONE: "Teléfono",
        CNPJ: "CNPJ",
        IE: "Registro estatal",
        IM: "Registro municipal",
        EMAIL: "Correo electrónico",
        OBS: "Observaciones",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        FORNECEDOR: "Proveedor",
        CAMPOOBRIGATORIO: "El campo de la ciudad es obligatorio",
        ERROSALVAR: "Error al guardar el proveedor: ",
        DEPARACONTABIL: "De-para contabil"
    },
    EXAMESLISTA: {    
        PROCURARPOR: "Procurar por ",
        APELIDO: "Apellido",
        NOME: "Nombre",
        SINONIMIA: "Sinonimia",
        SETOR: "Sector",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        ADICIONAL: "adicionales",
        EDITAR: "Editar",
        SELECIONECOD: "Seleccione un código",
        MANUTENCAO: "Mantenimiento",
        EXAMES: "Examenes",
    },
    EXAMECADASTRO: {
        APELIDO: "Apellido",
        NOME: "Nombre",
        SETOR: "Sector",
        GEN: "Género",
        MASC: "Hombre",
        FEM: "Mujer",
        ETQNUMERO: "Número de etiqueta",
        CLIQUESETA: "Haga clic en la flecha para buscar el siguiente valor no utilizado",
        ORDEM: "Orden en el resultado",
        QTDEAMOSTRA: "Cantidad de muestras",
        QTDEETQ: "Cantidad de etiquetas",
        PRAZODIA: "Días de plazo",
        PRAZOHORA: "Horas de plazo",
        PRAZOMIN: "Minutos de plazo",
        MINJEJUM: "Tiempo mínimo de ayuno",
        VOLDESEJADO: "Volumen deseado",
        VOLMIN: "Volumen mínimo",
        SINONIMIA: "Sinonimia",
        ULTIMOSRESUL: "Cantidad de últimos resultados",
        RECIP: "Contenedores",
        DOMINGO: "Domingo",
        SEGUNDA: "Martes",
        TERCA: "Martes",
        QUARTA: "Miércoles",
        QUINTA: "Jueves",
        SEXTA: "Viernes",
        SABADO: "Sábado",
        OBTAMOSTRA: "Obtención de una muestra",
        INFOLAB: "Información al laboratorio",
        INFOPACIENTE: "Información al paciente",
        RASBANCADA: "Borrador de la bancada",
        DESATIVADO: "Desactivado",
        POP: "POP",
        APLICLINICA: "Aplicación clínica / Interpretación",
        CRITREJEICAO: "Criterios de rechazo",
        CONSEXAME: "Consumibles de examen",
        PESCLINICA: "Investigación clínica",
        EXACOMPLEMENTAR: "Exámenes complementarios",
        TABPRECO: "Listas de precios",
        BLOQEXA: "Examen en bloque",
        DESBLOQEXA: "Desbloquear el examen",
        VAL: "Valor",
        BLOQ: "Bloqueado",
        BLOQMOTIVO: "Motivo del bloqueo",
        AUT: "Autorización",
        NEGOCIADO: "Negociado",
        VERSAOMASC: "Seleccione la versión de la máscara al escribir el resultado",
        NAOPUBLICA: "No publica los resultados en Internet",
        EMPLANTAO: "Realizado en régimen de guardia",
        EXAFATU: "Examen de facturación",
        EXAPERFIL: "Examen de perfil",
        INFOMED: "Informar del uso de la medicación",
        INFOHIPOTESEDIAGNOSTICA: "Informar Hipótesis Diagnóstica",
        INFODUM: "Informar de la fecha de la última menstruación",
        INFOPESO: "Informar del peso del paciente",
        INFOALTURA: "Informar de la altura del paciente",
        LIBERAPARCIAL: "Liberar el resultado parcial",
        BLOQREPETIDO: "Bloqueo de la repetición de exámenes",
        LIBAUTO: "Editar la liberación automática",
        EDITARMASCARA: "Editar la máscara de resultados",
        CODINTEG: "Códigos de integración",
        TIPO: "Tipo",
        CODIGO: "Código",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        EXAME: "Examen",
        APELIDOEXISTE: "El apellido ya existe, por favor intente otro.",
        OUTRASDESPESAS: "Examen de otros gastos",
        EXAMEALIQUOTA: "Examen para Aliquotagem",        
        CODIGOTABELA: "Código de tabla",
        CODIGODESPESA: "Código de Gastos",
        ALERTACAMPOAPELIDO: "El campo de apellido debe ser llenado",
        ALERTACAMPONOME:"El campo de nombre debe ser llenado",
        LAUDORESTRITO: "Informe restringido",       
    },
    MATERIAISLISTA: {
        PROCURAR: "Buscar en",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        MATERIAIS: "Materiales",
    },
    MATERIALCADASTRO: {
        SALVANDOMATERIAL: "El material se está guardando, este proceso puede tardar unos minutos.",
        NOME: "Nombre",
        CODINTEG: "Códigos de integración",
        TIPO: "Tipo",
        CODIGO: "Código",
        INTEG: "Integrador",
        ADDCOD: "Añadir código",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        MATERIAL: "Material",
    },
    CONSERVANTESLISTA: {
        PROCURAR: "Procurar por nombre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        NOME: "Nombre",
        MANUTENCAO: "Mantenimiento",
        CONSERVANTES: "Conservantes",
    },
    CONSERVANTECADASTRO: {
        NOME: "Nombre",
        CODINTEG: "Códigos de integración",
        TIPO: "Tipo",
        CODIGO: "Código",
        INTEG: "Integrador",
        ADDCODIGO: "Añadir código",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        CONSERVANTE: "Conservador",
        ERROSALVAR: "Error al guardar el conservante: ",
    },
    ABREVIATURAS: {
        PROCURAR: "Buscar enmbre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        NOME: "Nombre",
        MANUTENCAO: "Mantenimiento",
        ABREVIATURAS: "Abreviaciones",
    },
    ABREVIATURACADASTRO: {
        CODIGO: "Código",
        NOME: "Nombre",
        SALVAR: "Guardar",
        DESATIVADO:"Desactivar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        ABREVIATURA: "Abreviación",
    },
    GRUPOSEXAMELISTA: {
        PROCURAR: "Buscar enmbre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        NOME: "Nombre",
        EXAME: "Exámenes",
        MANUTENCAO: "Mantenimiento",
        GRUPOEXAME: "Grupo de examen",
    },
    GRUPOSEXAMECADASTRO: {
        NOME: "Nombre",
        EXAME: "Exámenes",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        GRUPOEXAME: "Grupo de examen",
        ERROSALVAR: "Error al guardar el grupo de exámenes: ",
    },
    BUSCAEXAME: {
        APELIDO: "Apellido",
        NOME: "Nombre",
        SETOR: "Sector",
        RECIPIENTES: "Contenedor",
        MATERIAL: "Material",
        CONSERVANTE: "Conservador",
        INCLUIR: "Incluir",
    },
    BUSCACONVENIO: {
        ID: "Id",
        NOME: "Nombre",
        INCLUIR: "Incluir",
    },
    TIPOCODIGOLISTA: {
        PROCURAR: "Buscar en",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        TIPOSCODIGO: "Tipos de código de examen",
    },
    TIPOCODIGOCADASTRO: {
        SALVANDOTIPO: "El tipo de código de examen se está guardando, este proceso puede tardar unos minutos.",
        NOME: "Nombre",
        SALVAR: "Guardas",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        TIPOSCODIGO: "Tipos de código de examen",
    },
    SETORESLISTAR: {
        PROCURAR: "Buscar en",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        SETORES: "Sectores",
    },
    SETORESCADASTRO: {
        SALVARSETOR: "El sector se está guardando, este proceso puede tardar unos minutos.",
        NOME: "Nombre",
        AGRUPASETOR: "Grupo con sector",
        SELECIONASETOR: "Seleccione el sector",
        DEPTO: "Departamento",
        SELEDEPTO: "Seleccione el departamento",
        MODELETQ: "Ejemplo de etiqueta",
        QTDEEXAME: "Cantidad de pruebas por muestra",
        COR: "Color",
        INTEG: "Integradores",
        ID: "Id",
        SELECINTEG: "Seleccione el integrador",
        ADDINTEG: "Añadir integrador",
        SETOR: "Sector",
        NAOBANCADA: "No emite bancada",
        COMRASCUNHO: "Bancada emisor con borrador",
        NAOCRIA: "No crea muestras automáticamente en la recepción",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        SETORES: "Sector",
        EXAMESIMAGEM: "Exámenes de imagen",
        EXAMESNOTIFICAR: "Notificar exámenes en los días que se realizan",
        ALIQUOTAAUTOMATICA: "Aliquota Automática",
        CENTRORESULTADOS: "Centro de resultados"        
    },
    DEPTOLISTA: {
        PROCURAR: "Buscar enmbre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        NOME: "Nombre",
        MANUTENCAO: "Mantenimiento",
        DEPTOS: "Departamentos",
    },
    DEPTOCADASTRO: {
        NOME: "Nombre",
        FATURA: "Factura",
        CRIAFATURA: "Crear facturas por separado",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
    },
    TABPRECOLISTA: {
        PROCURAR: "Buscar en",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        TIPO: "Tipo",
        MANUTENCAO: "Mantenimiento",
        TABPRECO: "Listas de precios",
    },
    TABPRECOCADASTRO: {
        SALVARTAB: "La tabla de precios se está guardando, este proceso puede tardar unos minutos.",
        NOME: "Nombre",
        TIPO: "Tipo",
        SELTIPO: "Seleccione el tipo",
        PROCURAPOR: "Buscar por ",
        APELIDO: "Apellido",
        CODINTEG: "Códigos de integración",
        REAJUSTEVAL: "Reajuste de los valores",
        BLOQEXA: "Bloquear todos los exámenes ? ",
        APENASBLOQ: "Mostrar sólo bloqueado",
        PERCENTUAL: "Qué porcentaje quiere cambiar en los valores de la tabla?",
        SIM: "Sí",
        NAO: "No",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        VAL: "Valor",
        QTDEUS: "Cantidad US",
        PERCENTUALPORTE: "PorcentajePuerto",
        CUSTOOP: "CosteExplotación",
        VALCBHPM: "ValorCBHPM",
        BLOQ: "Bloqueado",
        MOTIVOBLOQ: "Motivo del bloqueo",
        AUT: "Autorización",
        NEGOCIADO: "Negociado",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        TABPRECO: "Listas de precios",
        DESATIVAR: "Desactivar",
        FILME: "Cantidad Película",
        CONSUMIVEL: "Consumivel",
        ID: "Id",
        PROCURAR: "Buscar",
        SENHA: "Contraseña",
    },
    VALCBHPM: {
        PROCURAR: "Buscar enmbre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        VAL: "Valor",
        MANUTENCAO: "Mantenimiento",
        VALCB: "Valores CBHPM",
    },
    VALCBHPMCADASTRO: {
        CODIGO: "Código",
        VAL: "Valor",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        ERROSALVAR: "Error al guardar el valor de cbhpm: ",
    },
    MOTIVOSLISTA: {
        PROCURAR: "Buscar enmbre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        DESCINTERNA: "Descripción interna",
        DESEXTERNA: "Descripción Externa",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        MOTIVOS: "Razones",
    },
    MOTIVOCADASTRO: {
        DESCINTERNA: "Descripción interna",
        DESEXTERNA: "Descripción Externa",
        DESTINO: "Destinos",
        ENVIAEMAIL: "Envío de correo electrónico",
        NOPORTAL: "Aparece en el Portal",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        MOTIVOS: "Razones",
        ERROSALVAR: "Error al guardar la razón: ",
    },
    LIBERACAOAUTOLISTA: {
        PROCURARPOR: "Búsqueda por apellido de examen",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        APELIDO: "Apellido",
        MANUTENCAO: "Mantenimiento",
        LIBAUTO: "Liberación automática",
    },
    LIBERACAOAUTOCADASTRO: {
        FILTROS: "Filtros",
        VERSAOMASC: "Versión de la máscara",
        SELECVERSAO: "Seleccione una versión para filtrar",
        SELECCAMPO: "Seleccione un campo para filtrar",
        CARREGARREF: "Cargar los valores de referencia",
        SEMREF: "No hay valores de referencia configurados para este campo",
        EXPRESSAO: "Expresión",
        VALINICIAL: "Valor Inicial",
        VALFINAL: "Valor Final",
        IMPORTAR: "Importar",
        LIBAUTOMATICA: "Liberación automática",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        LIBAUTO: "Liberación automática",
        ERROSALVAR: "Error al guardar la configuración: ",
        ERROREGISTRO: "Se ha producido un error al importar los registros",
        NAOENCONSTRADO: "No se encontró ningún examen con ese apellido.",
    },
    LIBERACAOAUTOMANUTENCAO: {
        CODIGO: "Código",
        MASCARA: "Máscara",
        CAMPO: "Campo",
        SEXO: "Sexo",
        IDADEMAIOR: "Edad > Qué",
        IDADEMENOR: "Edad < Qué",
        VALMIN: "Valor Mínimo (>)",
        VALMAX: "Valor Máximo (<=)",
        CAMPOUN: "Campo Único",
        HABILITADA: "Activado",
        PROXLIB: "Próxima publicación",
        PRIMEIROCAMPO: "Primer Campo",
        ULTIMOCAMPO: "Último Campo",
        INCLUIR: "Incluir",
        SUCESSO: "Filtros importados con éxito",
    },
    REDUCAOACRESCIMOCADASTRO: {
        MANUTENCAO: "Manutención",
        REDUCAOACRESCIMO: "Reducción/Adición",
        NOME: "Nombre",
        PRIMEIROEXAME: "Primeiro Examen",
        SEGUNDOEXAME: "Segundo Examen",
        TERCEIROEXAME: "Tercero Examen",
        DEMAIS: "Otros Exámenes",
        SETOR: "Sector",
        TABELA: "Tabla",
        CH: "CH",
        CO: "CO",
        PORTE: "Porte",
        FILME: "Filme",
        ERROSALVAR: "Error al guardar "
    },
    REDUCAOACRESCIMOLISTA: {
        PROCURAR: "Buscar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
    },
    REPASSEMEDICOCADASTRO: {
        MANUTENCAO: "Manutención",
        REPASSEMEDICO: "Traslado Médico",
        NOME: "Nombre",
        VALORMINIMO: "Valor Mínimo",
        PORCENTAGEM: "Porcentaje",
        IMPOSTOS: "Impuestos",
        FUNCIONARIO: "Empleado",
        CONVENIO: "Convenio",
        UNIDADE: "Unidade",
        SETOR: "Sector",
        TABELADEEXAMES: "Tabla de Exámenes",
        ERROSALVAR: "Error al guardar ",
        INSIRANOMEREPASSE: "¡Ingrese el nombre del traslado médico!",
        INSIRAVALORMINIMO: "¡Ingrese la cantidad mínima!",
        INSIRAIDMEDICO: "¡Ingrese el ID del Empleado!",
        DESCONTOGUIA: "Considere el descuento de la guia",
    },
    REPASSEMEDICOLISTA: {
        PROCURAR: "Buscar en",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        TIPO: "Tipo",
        VALORMINIMO: "Valor Mínimo",
        PORCENTAGEM: "Porcentaje",
        IMPOSTOS: "Impuestos",
        MEDICO: "Solicitante",
        CONVENIO: "Convenio",
        SETOR: "Sector",
        TABELADEEXAMES: "Tabla de Exámenes",
    },
    UNIDADELISTA: {
        PROCURAR: "Buscar en", 
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        UNIDADES: "Unidades",
    },
    UNIDADECADASTRO: {
        SALVANDOUNIDADE: "La unidad se está guardando, este proceso puede tardar unos minutos.",
        NOME: "Nombre",
        HORAPADRAO: "Hora estándar de retorno",
        FIMEXP: "Fin de lo Expediente",
        MODELORES: "Modelo de Resultados",
        MODELOSEL: "Seleccione el modelo",
        DESATIVADO: "Desactivado",
        CODINTEG: "Códigos de integración",
        CODIGO: "Código",
        TIPO: "Tipo",
        INTEG: "Integrador",
        ADDCODIGO: "Añadir código",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        UNIDADE: "Unidade",
        CONFIGNF: "Configurações NF",
        CAMPOSNF: "Campos NF",
        VALORESNF: "Valores",
        ADDCONFIG: "Adicionar configuração"
    },
    LOCAISLISTA: {
        PROCURAR: "Buscar enmbre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        NOME: "Nombre",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        LOCAIS: "Lugares",
    },
    LOCALCADASTRO: {
        NOME: "Nombre",
        MODELORESULTADO: "Modelo de Resultado",
        SELECIONEMODELO: "Seleccione un Modelo",
        EMAIL: "Correo electrónico",
        TIPOLOCAL: "Tipo de Lugar",
        SELTIPOLOCAL: "Seleccione un tipo de lugar",
        ENDERECO: "Dirección",
        URGENCIAMIN: "Minutos de urgencia",
        FUNC: "Empleado",
        UNIDADE: "Unidade",
        PUBINTERNET: "Publican los resultados en Internet?",
        PACIENTESEMRES: "Los pacientes no tienen acceso a los resultados",
        URGENTE: "Urgente",
        DESATIVADO: "Desactivado",
        USUARIO: "Usuário",
        USUARIOWEB: "Usuário web",
        CODINTEG: "Códigos de integración",
        CODIGO: "Codigo",
        TIPO: "Tipo",
        INTEGRADOR: "Integrador",
        ADDCOD: "Añadir código",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        LOCAL: "Local",
        EMAILVAZIO: "Hay que rellenar el campo del correo electrónico",
        ERROSALVAR: "Error al guardar la ficha local",
        OEMAIL: "El correo electrónico ",
        EMUSO: " ya está siendo utilizado por el ",
        DESATIVARUSUARIO: "Desactivar usuário Web",
        ERROEXCLUIR: "Error al eliminar el usuario: ",
    },
    TIPOLOCAISLISTA: {
        PROCURAR: "Buscar en",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        ACIDENTE: "Accidente de trabajo",
        COLETADOM: "Recogida a domicilio",
        TIPOLOCAL: "Tipos de lugares",
    },
    TIPOLOCALCADASTRO: {
        NOME: "Nombre",
        ACIDENTE: "Accidente de trabajo",
        COLETADOM: "Recogida a domicilio",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        TIPOLOCAL: "Tipo de lugar",
    },
    REGRAAGENDAMENTOLISTA: {
        PROCURAR: "Buscar en",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        SLOT: "Slot",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        TIPOAGENDAMENTO: "Normas de programación",
    },
    REGRAAGENDAMENTOCADASTRO: {
        SALVANDOAGENDAMENTO: "A norma de programación se está guardando, este proceso puede tardar unos minutos.",
        NOME: "Nombre",                                
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        EXAME: "Exame",
        MANUTENCAO: "Mantenimiento",
        REGRAAGENDAMENTO: "Regra de agendamento",
        TIPOAGENDAMENTO: "Tipo de agendamento",
        FUNCIONARIO: "Funcionário",
        REGRAS: "Normas de programación",        
        INCLUIR: "Incluir",        
        ATENCAO: "Atención",        
        CAMPOSOBRIGATORIOS: "Rellene los campos obligatorios",        
        SLOT: "Slot",
        CONJUNTOCOMPLETO: "Conjunto Completo",
    },             
    TIPOAGENDAMENTOLISTA: {
        PROCURAR: "Buscar en",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        TIPOAGENDAMENTO: "Tipos de programación",
    },
    TIPOAGENDAMENTOCADASTRO: {
        SALVANDOAGENDAMENTO: "La clase de programación se está guardando, este proceso puede tardar unos minutos.",
        NOME: "Nombre",
        COR: "Color",
        HORAINICIO: "Hora de inicio del trabajo",
        HORAFIM: "Hora de finalización del trabajo",
        INTERVALO: "Duración del intervalo (en minutos)",
        UNIDADE: "Unidad",
        DIASREALIZACAO: "Días de realización",
        DOMINGO: "Domingo",
        SEGUNDA: "Martes",
        TERCA: "Martes",
        QUARTA: "Miércoles",
        QUINTA: "Jueves",
        SEXTA: "Viernes",
        SABADO: "Sábado",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        TIPOAGENDAMENTO: "Tipo de programación",
        ESCALA: "Escalas",
        REGRAS: "Reglas de programación ",
        IDESCALA: "Id",
        DIADASEMANA: "Dia da Semana",
        NOMEESCALA: "Nome",
        INCLUIR: "Incluir",
        ATENCAO: "Atención!",        
        CAMPOSOBRIGATORIOS: "Rellene todos los campos!",
        EQUIPAMENTOS: "Equipos",    
        SEXO: "Sexo",
        SELECIONARSEXO: "Restricción de sexo?",
        FEM: "Femenino",
        MASC: "Masculino",
        TODOS: "Todos",
        IDADEMIN: "Edad Mínima",
        IDADEMAX: "Edad Máxima",
        LIMITEPESO: "Límite de Peso (kg)", 
        PERMITECONFLITO: "Permitir Conflito",       
        DESATIVADO: "Desactivado",
    },
    PESQUISASLISTA: {
        PROCURAR: "Buscar enmbre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        NOME: "Nombre",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        PESQUISA: "Investigación",
    },
    PESQUISACADASTRO: {
        NOME: "Nombre",
        DESTINO: "Búsqueda de destino",
        NENHUM: "Ninguna",
        MEDICO: "Solicitante",
        LOCAL: "Local",
        RECEPCAO: "Recepción",
        COLETA: "Colección",
        OBGT: "Obligatorio",
        COMPARTILHARGUIAS: "Compartir la respuesta con otros guías",
        COMPARTILHAREXAMES: "Compartir la respuesta con otros examenes",
        PERGUNTAS: "Preguntas",
        RESPOSTAS: "Respuestas",
        LIVRE: "Disponible?",
        DATA: "Fecha?",
        ARQV: "Archivo?",
        NUM: "Numérico?",
        INCLUIRRESP: "Incluya Respuesta",
        INCLUIRPERGUNTA: "Incluya Pregunta",
        CODINTEG: "Búsqueda de códigos de integración",
        TIPO: "Tipo",
        CODIGO: "Código",
        INTEG: "Integrador",
        ADDCODIGO: "Añadir código",
        CODINTEGPEG: "Códigos de integración Pregunta",
        CODINTEGRES: "Códigos de integración Respuestas",
        PEGITEM: "Pregunta Item",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        PESQUISA: "Búsqueda",
        ERROSALVAR: "Error al guardar la búsqueda: ",
        TRIAGEM: "Cribado",
        EXECUCAO: "Execução"
    },
    COLETADORESLISTA: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        NOME: "Nome",                
        COLETADORES: "Coletadores",
        CADASTRO: "Cadastro",
        DESATIVADO: "Desativado"
    },   
    COLETADORESCADASTRO: {
        NOME: "Nome",
        CONSELHO: "Conselho",
        DESATIVADO: "Desativado"
    
    },       
    FERIADOSLISTA: {
        PROCURAR: "Buscar en",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        FERIADOS: "Vacaciones",
    },
    FERIADOCADASTRO: {
        SALVANDOCADASTRO: "Las vacaciones se están guardando, este proceso puede tardar unos minutos.",
        NOME: "Nombre",
        DATA: "Fecha",
        ANO: "Año",
        FERIADOSNAC: "Importar fiestas nacionales",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        FERIADO: "Vacaciones",
        JAEXISTE: "Ya existe un día festivo con la misma fecha",
    },
    CONDICOESLISTA: {
        PROCURAR: "Buscar enmbre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        DESC: "Descripción",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        CONDPAG: "Condiciones de pago",
    },
    CONDICOESCADASTRO: {
        SALVANDOCADASTRO: "La condición de pago del examen se está guardando, este proceso puede tardar unos minutos.",
        DESC: "Descripción",
        ESPECIEPAG: "Tipo de pago",
        SELESPECIEPAG: "Seleccione los tipos de pago",
        QTDEPARCEL: "Cantidad de parcelas",
        BANDEIRA: "Bandera",
        SELBANDEIRA: "Seleccione la bandera",
        VENCIMENTO: "Fecha de vencimiento",
        VENCIMENTOPRIMEIRAPARCELA: "Prazo de Vencimento da Primeira Parcela",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        CONDPAG: "Condiciones de pago",
        EMITENOTAFISCAL: "Generar Factura",
        GERARBOLETO: "Generar Boleto",
        IMPOSTOS: "Impuestos",
        IMPOSTO: "Impuesto",
        ID: "Id",
        ALIQUOTA: "Aliquota",
        INCLUIR:"Incluir"
    },
    IMPOSTOCADASTRO: {
        SALVANDOCADASTRO: "Lo impuesto se está guardando, este proceso puede tardar unos minutos.",
        NOME: "Nombre",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        IMPOSTO: "Impuesto",
    },
    ESPECIEPAGAMENTOLISTA: {
        PROCURAR: "Buscar en",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        ESPECIEPAG: "Tipo de pago",
    },
    ESPECIEPAGAMENTOCADASTRO: {
        SALVANDOCADASTRO: "El tipo de pago se está guardando, este proceso puede tardar unos segundos.",
        NOME: "Nombre",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        ESPECIEPAG: "Tipos de pago",
    },
    RATEIOLISTA: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        RATEIOS: "Rateios",
    },   
    RATEIOCADASTRO: {
        MANUTENCAO: "Manutenção",
        RATEIOS: "Rateios",        
        CENTRORESULTADO: "Centro de Resultado",    
        PORCENTAGEM: "Porcentagem",    
        NOME: "Nome",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
    },       
    SOROTECALISTA: {
        PROCURAR: "Buscar en",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        GELADEIRAS: "Refrigeradores",
    },
    SOROTECACADASTRO: {
        MANUTENCAO: "Mantenimiento",
        GELADEIRAS: "Refrigeradores",
        SALVANDOCADASTRO: "La nevera se está guardando, este proceso puede tardar unos minutos.",
        NOME: "Nombre",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
    },
    SOROTECACONTAINERLISTA: {
        PROCURAR: "Buscar en",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        CONTAINERS: "Contenedores",
    },
    SOROTECACONTAINERCADASTRO: {
        SALVARCADASTRO: "El contenedor se está guardando, este proceso puede tardar unos minutos.",
        NOME: "Nombre",
        POSICAO: "Posiciones:",
        QTDECOLUN: "Cantidad de columnas:",
        QTDELINHAS: "Cantidad de líneas:",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        CONTAINERS: "Soroteca - Contenedor",
    },
    CONFIGTISSLISTA: {
        PROCURAR: "Buscar enmbre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        NOME: "Nombre",
        ID: "Id",
        EDITAR: "Editar",
        MANUTENCAO: "Mantenimiento",
        CONFIGTISS: "Configuración TISS",
    },
    CONFIGTISSCADASTRO: {
        NOME: "Nombre",
        ANS: "Registro ANS",
        LOG: "Dirección",
        COMPLEMENTO: "Complemento",
        MUN: "Condado",
        MUNIBGE: "Condado IBGE",
        UF: "UF",
        CNPJ: "CNPJ",
        CNES: "CNES",
        ULTIMOSEQ: "Último secuencial",
        ULTIMOLOTE: "Ultimo Lote",
        UNIDADES: "Unidades",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        CONFIGTISS: "Configuración TISS",
        ERROSALVAR: "Error al guardar la configuración de TISS",
    },
    WORKLIST:{
        AGENDAMENTOEXAMES:"Cronograma de Exames",
        PACIENTE:"Paciente",
        CONVENIO:"Convênio",
        EXAME:"Exame",
        AGENDA:"Cronograma",
        AGENDAMENTOREALIZADO:"Cronograma Realizado",
        SALVAR:"Salvar",
        ATENDIMENTO:"Atendimento",
        WORKLIST:"Worklist",
        ENCAIXE:"Encaixe",        
        AGENDADOEM:"Agendado",
        EXECUTANTE:"Executante",
        CHECKIN:"Enviar para Sala de Espera?",
        CONFIRMARCHECKIN:"O paciente está presente na clínica, deseja enviar para sala de espera?",
        ATENCAO:"Atención",
        EXISTEMEXAMESPENDENTES:"Hay exámenes que aún no han sido programados!",
        REENVIARWORKLIST: "Re-Enviar para Sala de Espera?",
        SELECIONEAGENDA: "Selecione a Agenda",
        SELECIONEEXECUTANTE: "Selecione o Executante",        
        ALTERARAGENDA: "Alterar Agenda",
        CANCELARAGENDAMENTO: "Cancelar Agendamento?",
        REALIZARENCAIXE: "Selecione a data e hora para o encaixe",
        
                        
    },    
    AGENDAMENTOTAREFA: {
        COMENTARIO: "Comentarios",
        COMANDO: "Comando",
        MIN: "Minutos",
        HORAS: "Horas",
        DIA: "Día",
        MES: "Mes",
        DIASEMANA: "Día de la semana",
        CONFIG: "Configuraciones",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Mantenimiento",
        AGENDATAREFA: "Programación de tareas",
        NAOPERMITIDO: "Comando no permitido.",
        ERROSALVAR: "Error al guardar la programación: ",
    },
    SOBRE: {
        APRESENTACAO: "Sistema de información para laboratorios de análisis clínicos",
        LICENCIADOPARA: "Este sistema tiene licencia para:",
        VERSAO: "versión"  
    },
    SIDEBAR: {
        ATENDIMENTO: "Servicio",
        ANALISE: "Análisis",
        EQUIPAMENTO: "Equipo",
        INTEGRACAO: "Integración",
        MANUTENCAO: "Mantenimiento",
        ESTOQUE: "Acción",
        FINANCEIRO: "Financiero",
        RELATORIO: "Informes",
        PLUGINS: "Complementos",
        SAIR: "Salir"
    },
    MODALNOTIFICACAO: {
        TITULO: "Sin notificación | 1 Notificación | {n} Notificaciones",
        FECHAR: "Cerrar",
        APAGARTODAS: "Borrar Todo",
        APAGAR: "Eliminar esta notificación",
        NOTIFICARGUIACOMPLETA: "Notificar guía completa"
    },
    ESTOQUE:{
        LISTARTODOS: "Listar todo",
        INCLUIR: "Incluir",
        PROCURAR: "Buscar",
        TITULO: "Stock",
        ESTOQUES: "Stocks",
        PEDIDOSAFORNECEDORES: "Pedidos a proveedores",
        MOVIMENTOODEESTOQUE: "Movimiento de Stock"
    },
    ESTOQUESLISTA:{
        TITULO: "Stock",
        TITULOESTOQUE: "Registro de Stocks",
        LISTARTODOS: "Listar todo",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        NOME: "Nombre",
        EDITAR: "Editar",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Próxima Anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        PROCURAR: "Buscar en"
    },
    ESTOQUECADASTRO: {
        NOME: "Nombre",
        TITULO: "Registro",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        ATENCAO: "Aviso",
        CAMPOSOBRIGATORIOS: "Rellene los campos obligatorios.",
        SALVANDO: "Guardando...",
        UNIDADE: "Unidad",
        SELECIONEUNIDADE: "Seleccione la unidad"
    },
    UNIDADEDEMEDIDALISTA:{
        TITULO: "Unidades de Medida",
        LISTARTODOS: "Listar Todo",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        NOME: "Nombre",
        FATOR: "Factor",
        UNIDADEREFERENCIA: "Unidad de Referencia",
        EDITAR: "Editar",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Próxima Anterior",
        PAGINA: "Página",
        PAGATUAL: "Página Actual",
        PROCURAR: "Buscar en"
    },
    UNIDADEDEMEDIDACADASTRO:{
        TITULO: "Unidades de Medida",
        LISTARTODOS: "Listar Todo",
        NOME: "Nombre",
        FATOR: "Factor",
        UNIDADEDEMEDIDADEREFERENCIA: "Unidad de Medida de Referencia",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",     
        SELECIONEUNIDADE: "Seleccionar la unidad de medida de referencia",
        SALVANDO: "Guardando...",
        CODIGOANS: "Código ANS",
    },
    CATEGORIALISTA:{
        TITULO: "Categoria",
        LISTARTODOS: "Listar Todo",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        NOME: "Nombre",
        EDITAR: "Editar",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Próxima Anterior",
        PAGINA: "Página",
        PAGATUAL: "Página Actual",
        PROCURAR: "Buscar en"
    },
    CATEGORIACADASTRO: {
        NOME: "Nombre",
        TITULO: "Categoria",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        ATENCAO: "Aviso",
        CAMPOSOBRIGATORIOS: "Rellene los campos obligatorio",
        SALVANDO: "Guardando...",
        UNIDADE: "Unidades",
        SELECIONEUNIDADE: "Seleccione la unidad"
    },
    MARCASLISTA:{
        TITULO: "Registro de la marca",
        LISTARTODOS: "Listar todo",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        NOME: "Nombre",
        EDITAR: "Editar",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Próxima Anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        PROCURAR: "Buscar en"
    },
    MARCASCADASTRO: {
        NOME: "Nombre",
        TITULO: "Registro",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        ATENCAO: "Aviso!",
        CAMPOSOBRIGATORIOS: "Rellene los campos obligatorios.",
        SALVANDO: "Guardando...",
    },
    CONSUMIVEISLISTA:{
        TITULO: "Consumibles",
        LISTARTODOS: "Listar Todo",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        NOME: "Nombre",
        CATEGORIA: "Categoria",
        MARCA: "Marca",
        EDITAR: "Editar",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Próxima Anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        PROCURAR: "Buscar en"
    },
    CONSUMIVELCADASTRO: {

        NOVAMARCA: "Nova marca",
        NOVACATEGORIA : "Nova categoria",
        NOVAUNIDADE: "Nova unidade de medida",        
        NOME: "Nombre",
        ESTOQUECONTROLADO: "Acciones controladas",
        SERVICO: "Servicio",
        REGISTROMS: "Registro en el Ministerio de Sanidad",
        CODIGODEBARRAS: "Código de Barras",
        CATEGORIA: "Categoria",
        MARCA: "Marca",
        UNIDADESDEMEDIDA: "Unidad de Medida",
        QUANTIDADEMINIMA: "Quantidade Mínima",
        OBSERVACOES: "Observaciones",
        TITULO: "Registro de consumibles",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        ATENCAO: "Aviso!",
        CAMPOSOBRIGATORIOS: "Rellene los campos obligatorios.",
        SALVANDO: "Guardando...",
    },
    LOTESLISTA:{
        TITULO: "Lotes",
        LISTARTODOS: "Listar Todos",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        NOME: "Nombre",
        LOTE: "Lote",
        CONSUMIVEL: "Consumible",
        VALIDADE: "Validez",
        EDITAR: "Editar",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Próxima Anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        PROCURAR: "Buscar en"
    },
    LOTECADASTRO: {
        NOME: "Nombre",
        CONSUMIVEL: "Consumible",
        DIASAVISO: "Dias de Aviso",
        VALIDADE: "Validez",
        TITULO: "Registro de Lote",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        ATENCAO: "Aviso!",
        CAMPOSOBRIGATORIOS: "Rellene los campos obligatorios.",
        SALVANDO: "Guardando...",
    },
    BUSCAEQUIPAMENTO: {
        ID: "Id",
        EQUIPAMENTO: "Equipo",
        INCLUIR: "Incluir",
    },
    ESTUDOLAUDO:{
        BUSCARLAUDO: "Modelo de Laudo",
        TITULOMODELOLAUDO: "Modelo de Laudo obligatorio",
        MESANGEMMODELOLAUDO: "Especifique o seleccione un modelo de laudo para continuar.",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        EHVALIDO: "¿Validado?",
        LAUDOESTRUTURADO: "Informe Estructurado",

    },
    ESTUDOSSEMVINCULO: {
        TITULOVINCULAR: "Muestra de enlaces",
        CONFIRMAVINCULAR: "Puede confirmar lo enlace entre los estudios y la guía y el examen?",
        CODIGO: "Código de la guía",
        EXAME: "Posición del examen en la guía",
        CANCELAR: "Cancelar",
    },
    ESTUDO:{
        GUIA: "Guía:",
        ACCESSIONNUMBER: "AccessionNumber:",
        MOSTRAPACIENTESEXOIDADE: "Paciente, sexo, edad:",
        MOSTRARACAESPECIE: "Raza, especie:",
        CRM: "Consejo:",
        SERIES: " series",
        IMAGENS: " imágenes",
        ALERTADESVINCULARESTUDO: "Solo se pueden desvincular los exámenes no laudados",
    },
    ESTUDOS:{
        PLACEHOLDERPROCURARPACIENTE: "Buscar enmbre",
        ACCESSIONNUMBER: "Número de acceso",
        PLACEHOLDERPROCURARACCESSIONUMBER: "Buscar por número",
        SETOR: "Sector",
        DEPARTAMENTO: "Departamento",
        UNIDADE: "Unidad",
        EXECUTANTE: "Ejecutante",
        FILTRAPERIODO: "Periodo",
        FILTRAPORSOLICITACAO: "Solicitación",
        FILTRAPORESTUDORECEBIDO: "Estudio Recibido",
        FILTRAPORRETORNO: "Regreso",
        URGENTE: "Urgente",
        FILTRARSTATUS: "Buscar por status",
        FILTRARTODOS: "Todos",
        SELECIONARCONVENIOS: "Seleccione el(los) convenio(s)",
        SELECIONARSETORES: "Seleccione el(los) sector(es)",
        SELECIONARDEPARTAMENTOS: "Seleccione el(los) departamento(s)",
        SELECIONARUNIDADES: "Seleccione la(s) unidad(es)",
    },
    MODALRETIFICALAUDO: {
        RETIFICACAO: "Cancelar examen",
        NOTIFICAÇÃO: "Este resultado ya estaba firmado. Indique el motivo de la rectificación",
        EXAMES: "Exámenes",
        MOTIVOS: "Razones",
        CONFIRMARCANCELAMENTO: "Confirmar rectificación",
        CANCELAR: "Cancelar",
    },
    PAINELATENDIMENTO: {
        SENHAATENDIMENTO: "Contraseña de servicio",
        GUICHE: "Ventanilla",
        ULTIMASCHAMADAS: "Últimas llamadas",
    },
    TOTENATENDIMENTO: {
        OPCAODESEJADA: "Seleccione la opción que desee.",
    },
    MODALFILAATENDIMENTO: {
        SENHAATENDIMENTO: "Contraseña de servicio",
        UNIDADE: "Unidad",
        GUICHE: "Ventanilla",
        SITUACAO: "Situación",
        TODOS: "Todos",
        RECEPCAO: "Recepción",
        COLETA: "Colección",
        TIPO: "Tipo",
        SEQUENCIA: "Secuencia",
        CHAMAR: "Llamar",
        ATENDER: "Atender",
        CANCELAR: "Cancelar",
        TEMPOESPERA: "Tiempo de espera",
        FECHAR: "Cerrar",
    },
    MODELOLAUDODESCRITIVO: {
        MANUTENCAO: "Mantenimiento",
        MODELOLAUDODESCRITIVO: "Modelo de Reportes Descriptivos",
        NOME: "Nombre",
        SETOR: "Sector",
        UNIDADE: "Unidad",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        SOLICITANTE: "Solicitante",
        TIPOEVENTO: "Tipo de acontecimiento",
    },
    MODELOLAUDODESCRITIVOLISTA: {
        PROCURAR: "Procurar por nombre",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Página siguiente",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
        ID: "Id",
        DESCRICAO: "Nombre",        
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        MODELOLAUDODESCRITIVO: "Modelo de Reportes Descriptivos",
    },           
    FATURAS: {
        FATURA: "Factura",
        DATA: "Fecha",
        SITUACAO: "Situación",
        PARCELAS: "Cuotas",
        DATADEVENCIMENTO: "Fecha de Vencimiento",
        PDF: "PDF",
        PDFSINTETICO: "PDF Sintético",
        CONSULTARNOTAFISCAL: "Consultar Nota",
        CONSULTARBOLETO: "Consultar Boleto",
    },
    MODALANEXO: {
        ANEXAR: "Adjuntar un estudio",
        ARRASTEARQUIVOS: "Arrastre los archivos aquí o haga clic para seleccionarlos",
    },
    EXAMEEXTRACAO: {
        ID: "Id",
        NOME: "Nome",
        EXAMEORIGEM: 'Examen Origem',
        EXAMEDESTINO: 'Examen Destino',
        EXTRACAOEXAME: "Extraccion Examen",                
        MANUTENCAO: "Mantenimiento",
        MODELETQ: "Ejemplo de etiqueta",
    },     
    STATUSAMOSTRA: {
        NOME: "Nombre",
        COR: "Color",
        ORDEM: "Orden",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        STATUS: "Status amostra",
        SALVANDOAGENDAMENTO: "El estado de la muestra se está guardando, este proceso puede tardar unos minutos.",
        MANUTENCAO: "Mantenimiento",
    },
    STATUSAMOSTRALISTA: {
        PROCURAR: "Buscar",
        INCLUIR: "Incluir",
        PROXPAGINA: "Página siguiente",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGINAATUAL: "Página actual",
        ID: "Id",
        NOME: "Nombre",
        ORDEM: "Orden",
        EDITAR: "Edite",
    },
    REPASSEMEDICOFINANCEIRO: {
        SUCESSO: "Transferencia registrada con éxito!",
        EXAME: "Exame",
        DATASOLICITACAO: "Fecha de solicitud",
        DATAASSINATURA: "Fecha de firma",
        VALORPROCEDIMENTO: "Precio del examen",
        VALORREPASSE: "Monto de la transferencia",
        VALORIMPOSTO: "Impuesto",
        VALORDESCONTO: "Descuento",
        VALORDESCONTOPONTUALIDADE: "Desc Esp",
        REPASSEPAGO: "Pagada",
        REGISTRARREPASSE: "Pagar",
        MEDICO: "Médico",
        CONVENIO: "Convenio",
        GUIA: "Guia",
        CANCELAR: "Cancelar",
        TOTAL: "Total"
    },
    AUTORIZACAOTISS: {
        PACIENTE: "Paciente",
        CARTEIRA: "Cartera",
        SOLICITANTE: "Solicitante",
        ORIGEM: "Fuente",
        GUIA: "Guia",
        SADTNUMERO: "Número",
        SENHA: "Contraseña",
        DATAAUTORIZACAO: "Fecha Autorización",
        DATAVALIDADE: "Fecha de expiración Autorización",
        PROCEDIMENTOS: "Procedimientos: ",
        QTDE: "Cantidad",
        VALOR: "Valor",
        DATAAGENDA: "Fecha Orden del día",
        LAUDADO: "Laudado",
        GUIACONFERIDA: "Guía comprobada",
        SALVAR: "Guardar",
        SELECIONARGUIA: "Debe seleccionar una pestaña.",
        SALVANDODADOS: "Guardar cambios",
        IMPRIMIRLAUDOS: "Imprimir informes",
    },
    MODALGEN: {
        QUANTIDADE: "Cantidad",
        AGRUPAMENTO: "Agrupación",
        MODELOETIQUETA: "Plantilla de etiquetas",
        CANCELAR: "Cancelar",
        IMPRIMIRETIQUETA: "Se solicita la impresión de etiquetas.",
    },    
    CENTRORESULTADOS: {
        ID: "Codigo",
        NOME: "Nombre",
        REGISTROPAI: "Registro pai",
        DEPARACONTABIL: "De-para contabil",
        SALVAR: "Guardar",
        NOVORAIZ: "Novo item raiz"
    },
    CATEGORIAFINANCEIRA: {
        ID: "Codigo",
        NOME: "Nombre",
        REGISTROPAI: "Registro pai",
        DEPARACONTABIL: "De-para contabil",
        SALVAR: "Guardar",
        NOVORAIZ: "Novo item raiz",
        TIPO: "Tipo",        
        RECEITA: "Receitas",
        DESPESA: "Despesas",
        CAMPONOMEOBRIGATORIO: "Campo Nome é obrigatório",      
        DRE: "Dre",
    },
    PRONTUARIO: {
        CODIGO: "CÓDIGO",
        IDADE: "EDAD",
        MASCULINO: "Hombre",
        FEMININO: "Mujer",
        SEXO: "SEXO",
        CONVENIO: "ACUERDO",
        CELULAR: "TELÉFONO MÓVIL",
        TELEFONE: "TELÉFONO",
        NOVOEVENTO: "Nuevo evento",
        NOME: "Nombre",
        BUSCARLAUDO: "Modelo de Laudo",
        TITULOMODELOLAUDO: "Modelo de Laudo obligatorio",
        MESANGEMMODELOLAUDO: "Especifique o seleccione un modelo de laudo para continuar.",
        LAUDO: "Laudo",
        IMAGEM: "Imagem"
    },
    DRELISTA: {
        PROCURAR: "Buscar",
        TITULO: "Cuenta de resultados del ejercicio",
        CODIGO: "Codigo",
        NOME: "Nombre",
        SEQUENCIA: "Secuencia",
        OPERADOR: "Operador",
        PROXPAGINA: "Página siguiente",
        PAGANTERIOR: "Página precedente",
        PAGINA: "Página",
        PAGATUAL: "Página actual",
    },
    DRECADASTRO: {
        NOME: "Nombre",
        SEQUENCIA: "Secuencia",
        OPERADOR: "Operador",
        PROXIMOVALOR: "Haga clic en la flecha para buscar el siguiente valor no utilizado",
        TITULO: "Cuenta de resultados del ejercicio",
        DESATIVADO: "Discapacitados",
    }
}