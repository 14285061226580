<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <div class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <searchIdName :leitura="model.id > 0" class="column" table="paciente" :label="$t('SISTEMA.PACIENTE')" 
                                                  :id.sync="model.pacienteId" :camposAgendamento="[{especie:'especie', raca: 'raca', responsavel: 'responsavel'}]">
                                        <slot>
                                            <b-button icon-left="plus-box"
                                                    tag="router-link"
                                                    :to="{ name: 'paciente', query: { retorno: true }, query: { retornarPaciente:true} }"
                                                    target="_blank"
                                                    :title="$t('AGENDAMODAL.NOVOPACIENTE')"></b-button>
                                        </slot>
                                    </searchIdName>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <searchIdName class="column" :leitura="!permiteAlterar" table="tipoagendamento" :label="$t('AGENDAMODAL.TIPOAGENDAMENTO')" :id.sync="model.tipoAgendamentoId">
                                        <slot>
                                            <b-button icon-left="plus-box"
                                                tag="router-link"
                                                :to="{ name: 'tipoagendamento', query: { retorno: true },query: { retornarTipoAgendamento:true }}"
                                                target="_blank"
                                                :title="$t('AGENDAMODAL.NOVOSTATUS')"></b-button>
                                        </slot>
                                    </searchIdName>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('AGENDAMODAL.DATAHORA')">
                                        <b-datetimepicker v-if="permiteAlterar" ref="data"
                                                        rounded
                                                        :placeholder="$t('AGENDAMODAL.SELECIONARHORA')"
                                                        v-model="model.dataHora"
                                                        icon="calendar-today"
                                                        :timepicker="{ enableSeconds: false, hourFormat: '24' }"
                                                        horizontal-time-picker>
                                        </b-datetimepicker>
                                        <span v-else >{{ $moment(model.datahora).format("DD/MM/YYYY HH:mm") }}</span>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('AGENDAMODAL.FUNCIONARIORESPONSAVEL')">
                                        <b-select v-model="model.funcionarioId">
                                            <option :value="null"></option>
                                            <template v-for="(item, index) in funcionariosAtivos">
                                                <option v-bind:key="index" :value="item.id">
                                                    {{item.nome}}
                                                </option>
                                            </template>
                                        </b-select>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('AGENDAMODAL.OBSERVACAO')">
                                        <b-input type="textarea"
                                                v-model="model.observacao"
                                                @input="$emit('update:observacao', $event)">
                                        </b-input>
                                    </b-field>
                                </div>
                            </div>
                        </div>                        
                    </div>

                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <historico-alteracoes :agendaLog="true" :chave="model.id" v-show="model.id"></historico-alteracoes>
                                <p v-if="model.dataHoraCriacao" class="panel-block">
                                    Agendado em {{ model.dataHoraCriacao | moment('DD/MM/YYYY [às] HH:mm') }}
                                    <br/>
                                    por {{ model.funcionarioNome }}
                                </p>                              
                        </article>
                    </div>                    
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('AGENDAMODAL.CONFIRMAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('AGENDAMODAL.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import { mapState } from 'vuex'   
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'
    import modalAlteracaoAgenda from '@/components/atendimento/modalAlteracaoAgenda'

    export default {
       
        data() {
            return {
                model: {
                    id: null,
                    pacienteId: null,
                    pacienteNome: null,
                    tipoAgendamentoId: null,
                    tipoAgendamentoNome: null,
                    unidadeId: null,
                    unidadeNome: null,
                    funcionarioId: null,
                    funcionarioNome: null,
                    funcionarioSelecionadoId: null,
                    funcionarioSelecionadoNome: null,
                    guiaId: null,
                    dataHora: null,
                    dataHoraOriginal: null,
                    dataHoraCriacao: null,                    
                    observacao: null,
                    status: null,
                    statusTitle: null,
                    encaixe: false
                },
                funcionariosAtivos: [],
                permiteAlterar: true,
                isLoading:false,          
            }
        },
        components: {
            titlebar,
            searchIdName,
            historicoAlteracoes
        },
        computed: {
            titleStack() {
                return [
                    this.$t('AGENDA.ATENDIMENTO'),
                    this.$t('AGENDA.AGENDA'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapState([
                'config',
                'unidadeId'
            ]),
        },
        created() {

            document.addEventListener('visibilitychange', this.verificaRetornar, false);            
        },         
        mounted() {
            this.$http.get('/api/search/FuncionariosAtivos?apenasCrmCadastrado=true')
            .then(res => {
                if (res.data != null) {
                    this.funcionariosAtivos = res.data
                }
            })

            if (this.$route.params.id) {
                this.isLoading = true;
                this.$http.get('/api/agenda/agendamento?id=' + this.$route.params.id)
                    .then(res => {                        
                        this.model = res.data
                        this.model.funcionarioId = this.model.funcionarioSelecionadoId;
                        this.model.dataHora = this.$moment(this.model.dataHora).toDate()
                        this.model.dataHoraOriginal = this.$moment(this.model.dataHora).toDate()
                        this.model.observacao = this.model.observacao ? this.model.observacao.replaceAll('\\n','\n') : ''; 
                        this.isLoading = false;
                    })
            }else{

                if(this.$route.params.tipoAgendamentoId){
                    this.model.tipoAgendamentoId = this.$route.params.tipoAgendamentoId;                    
                }

                if(this.$route.params.horario){
                    this.model.dataHora = this.$route.params.horario;                    
                }    
                                                                
            }


            if(this.$route.params.permiteAlterar)
                this.permiteAlterar =  this.$route.params.permiteAlterar;

            if(this.$route.params.encaixe)
                this.model.encaixe =  this.$route.params.encaixe;                
                        
            this.$forceUpdate(); 
        },
        methods: {

            verificaRetornar() {                                   
                if(localStorage.retornarTipoAgendamento > 0 && this.model.tipoAgendamentoId ==null){                                        
                    this.model.tipoAgendamentoId = window.localStorage.getItem('retornarTipoAgendamento');                    
                    localStorage.retornarTipoAgendamento = null;
                } 
                if(localStorage.retornarPaciente > 0 && this.model.pacienteId ==null ){                                          
                    this.model.pacienteId = window.localStorage.getItem('retornarPaciente');
                    localStorage.retornarPaciente = null;
                }                 
                                            
            },                         
            onSubmit() {

                //Verificar se houve alteracao no agendamento/data                
                if(this.model.dataHoraOriginal){                    
                    if(this.model.dataHoraOriginal.getDay() != this.model.dataHora.getDay() || this.model.dataHoraOriginal.getMonth() != this.model.dataHora.getMonth() 
                    || this.model.dataHoraOriginal.getFullYear() != this.model.dataHora.getFullYear() || this.model.dataHoraOriginal.getMinutes() != this.model.dataHora.getMinutes() 
                    || this.model.dataHoraOriginal.getHours() != this.model.dataHora.getHours() ){

                        this.$buefy.modal.open({
                            parent: this,
                            component: modalAlteracaoAgenda,
                            events: {
                                salvarAgendamento: this.salvarAgendamento
                            },
                            hasModalCard: true,
                            trapFocus: true
                        })
                    }else{
                        this.salvarAgendamento();
                    }

                }else{
                    this.salvarAgendamento();
                }                
                
            },
            salvarAgendamento(motivoAlteracao){
                this.isLoading = true;
                const params = [];
                if (this.model.id) params.push(`id=${this.model.id}`);
                params.push(`pacienteId=${this.model.pacienteId}`);
                params.push(`tipoAgendamentoId=${this.model.tipoAgendamentoId}`);
                if(this.model.funcionarioId == null) this.model.funcionarioId = 0;
                params.push(`funcionarioId=${this.model.funcionarioId}`);
                params.push(`encaixe=${this.model.encaixe}`);
                params.push(`datahora=${this.$moment(this.model.dataHora).format('YYYY-MM-DDTHH:mm:ss')}`);
                params.push(`observacao=${this.model.observacao ? this.model.observacao : ''}`);
                if (this.unidadeId != null)
                    params.push(`unidadeId=${this.unidadeId}`)

                if (motivoAlteracao!= null)
                    params.push(`motivo=${motivoAlteracao}`)                    

                const url = this.model.id
                    ? `/api/agenda/agendaEditar?${params.join('&')}`
                    : `/api/agenda/agendaincluir?${params.join('&')}`
                
                               
                this.$http.post(url)
                    .then(res => res.data)
                    .then(data => {
                            if (data.mensagem != null && data.mensagem != "") {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: data.mensagem,
                                type: 'is-danger',
                                queue: false
                            })
                        }else{
                            this.$router.back()
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('AGENDA.ERROSALVAR'),
                            type: 'is-danger',
                            queue: false
                        })
                    }).finally(() => {                        
                        this.isLoading = false;
                    }); 
            },
            validarCampos(){

                if(!this.model.pacienteId){

                    this.$buefy.toast.open({
                        duration: 5000,
                        message: "Preencha o paciente",
                        type: 'is-danger',
                        queue: false
                    });          
                    return false;          
                } 

                if(!this.model.tipoAgendamentoId){

                    this.$buefy.toast.open({
                        duration: 5000,
                        message: "Preencha o tipo de agendamento",
                        type: 'is-danger',
                        queue: false
                    });                    
                    return false;
                } 
                
                if(!this.model.dataHora){

                    this.$buefy.toast.open({
                        duration: 5000,
                        message: "Preencha a data do agendamento",
                        type: 'is-danger',
                        queue: false
                    }); 
                    
                    return false;
                }                 
                                
            }        
        }        
    }
</script>